import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import ListGroup from "react-bootstrap/ListGroup";
import "./menu.css";
import { useDispatch } from "react-redux";
import { logout } from "../../root/redux/actions/auth/logout";


const Menu = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSignOut = () => {
    dispatch(logout());
  }
  useEffect(() => {
    handleClose();
  }, [location]);

  return (
    <>
    <div className="menu">
      <NavLink className="menuLogo" to="/">
        Werk<span>APP</span>
        <p className="menuLogoSubtitle">Sales</p>
      </NavLink>
      <div className="menuLinks">
        <NavLink to="/clients" className="menuLink">Meine Kunden</NavLink>
        <NavLink to="/areas" className="menuLink">Mein Gebiet</NavLink>
        <NavLink to="/team" className="menuLink">Sales Team</NavLink>
        <NavLink to="/products" className="menuLink">Produkte</NavLink>
        {
          /*
          <NavLink to="/settings" className="menuLink">Einstellungen</NavLink>
          */
        }
        <NavLink to="/personal" className="menuLink">Mein Bereich</NavLink>
        <Button variant="outline-danger" size="sm" style={{borderRadius:"50px"}} onClick={handleSignOut}>Beenden</Button>
      </div>

      <Button className="mobileMenuBtn" variant="outline-primary" size="sm" style={{borderRadius:"50px"}} onClick={handleShow}>Menü</Button>

    </div>
    <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
        <p className="menuLogo">Werk<span>APP</span></p>
      </Offcanvas.Header>
      <Offcanvas.Body style={{display: "flex", flexDirection: "column",justifyContent: 'space-between'}}>
        <ListGroup variant="flush" className="my-4">
          <ListGroup.Item action as={NavLink} to="/clients">
            Meine Kunden
          </ListGroup.Item>
          <ListGroup.Item action as={NavLink} to="/areas">
            Mein Gebiet
          </ListGroup.Item>
          <ListGroup.Item action as={NavLink} to="/team">
            Sales Team
          </ListGroup.Item>
          <ListGroup.Item action as={NavLink} to="/products">
            Produkte
          </ListGroup.Item>
          {
          /*
          <ListGroup.Item action as={NavLink} to="/settings">
            Einstellungen
          </ListGroup.Item>
          */
          }
          <ListGroup.Item action as={NavLink} to="/personal">
            Mein Bereich
          </ListGroup.Item>
        </ListGroup>
        <Button variant="danger" style={{borderRadius:"50px"}} className="mb-4" onClick={handleSignOut}>Beenden</Button>
      </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default Menu