import {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { defaultData } from "./defaultData";
import { saver } from "../../../root/redux/actions/database/saver";
import { useDispatch, useSelector } from "react-redux";
import { updater } from "../../../root/redux/actions/database/updater";
import History from "../../modals/History";
import PersonsList from "../persons/PersonsList";
import Visits from "../visits/Visits";
import { loader } from "../../../root/redux/actions/database/loader";
import Loading from "../../modals/Loading";
import Error from "../../modals/Error";
import Contract from "../contract/Contract";

const MultiTool = ({obj = null, objId = null, mode = "detail", closeModal}) => {
  
  const { branchen, quellen } = useSelector(state => state.app.settings);
  const { zip, zipLoaded, zipError, areas, areasLoaded, areasError} = useSelector(state => state.app);
  const { uid, tokens } = useSelector(state => state.auth);
  // State for view modus
  const [showMode, setMode] = useState(mode);
  // Show Mode for History
  const [page, setPage] = useState("");
  // State for Editing active or not.
  const [editMode, setEdit] = useState(false);

  const [handleContract, setHandleContract] = useState(false);
  // Set old data if there is some.
  const [contact, setContact] = useState((obj !== null ? {...defaultData, ...obj}:{...defaultData}));
  // State to see if there are any changes.
  const [isDirty, setDirty] = useState(false);
  // State to validate the form.
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();
  // handle changes in the form.
  const handleChange = e => {
    setDirty(true);
    setContact(prev => ({...prev, [e.target.name]: e.target.value }));
  }
  const handleReset = e => {
    if(window.confirm("Alle Eingaben verwerfen?")) {
      if(showMode === "edit") {
        setContact({...defaultData, ...obj});
        setValidated(false);
        setDirty(false);
        setMode("detail");
      }
      else {
        setContact({...defaultData});
        setValidated(false);
        setDirty(false);
        closeModal();
      }
    }
  }
  const handleSave = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      console.log("error found!");
      return;
    } 
    setValidated(true);
    let area = {};
    let district = null;
    if(zip!==null) {
      const contactZip = contact.PLZ.substring(0,3);
      for (const item in zip) {
        if(zip[item].zip === contactZip) {
          area = {}
          if (zip[item]?.country) {
            area[zip[item].country] = true;
          }
          if (zip[item]?.region) {
            area[zip[item].region] = true;
          }
          if (zip[item]?.area) {
            area[zip[item].area] = true;
          }
          if (zip[item]?.district) {
            area[zip[item].district] = true;
          }
          district = areas[zip[item]?.district]?.user || "";
        }
      }
    }
    let assignedTo = ((tokens.role === "adm" || tokens.role === "hv") && !area.hasOwnProperty(tokens.area)) ? uid : null;

    if(showMode === "edit" && objId !== null) {
      updater("contacts", {...contact, area, district}, objId, obj);
    }
    if(showMode === "add") {
      saver("contacts",{...contact, area, assignedTo, district});
    }
    setContact(contact)
    setDirty(false);
    setValidated(false);
    setMode("detail");
  }

  useEffect(() => {
    setEdit((showMode === "edit" || showMode === "add"));
  }, [showMode, mode]);

  useEffect(() => {
    if(!zipLoaded) {
      dispatch(loader("zip"));
    }
    if(!areasLoaded) {
      dispatch(loader("areas"));
    }
  }, [dispatch, areasLoaded, zipLoaded]);

  if(!zipLoaded || !areasLoaded) return <Loading />;

  if(zipError !== null || areasError !== null) return <Error />;

  if(page === "history") {
    return <History obj={{...obj, id: objId}} type="contacts" goBack={() => setPage("")} />;
  }

  const getAreaName = (zipCode, show = "district") => {
    if(zipCode === null) return "";
    const zC = zipCode.substring(0,3);
    for (const item in zip) {
      if(zip[item].zip === zC) {
          if (show==="region" && zip[item]?.region) return areas[zip[item]?.region]?.name;
          else if (show==="area" && zip[item]?.area) return areas[zip[item]?.area]?.name;
          else if (show==="district" && zip[item]?.district) return areas[zip[item]?.district].name;
      };
    }
    return "Nicht zugeordnet";
  }

  if(handleContract) return <Contract obj={obj} objId={objId} goBack={() => setHandleContract(false)} />;


  return (
    <>
      {
        contact?.online && <h3 className="text-danger">Online Kunde!</h3>
      }
      { (contact?.PLZ && contact.PLZ.length >= 3 ) &&
        <Row>
          <Col className="fw-semibold mb-3">
            { getAreaName(contact.PLZ, "region") } &gt; { getAreaName(contact.PLZ, "area") } &gt; { getAreaName(contact.PLZ, "district")}
          </Col>
        </Row>
      }
      <Form noValidate validated={validated} onSubmit={handleSave}>
        <Row>
        <Col className="mb-4" xs={12} md={6} lg={3}>
          <FormGroup>
            <Form.Text>Status</Form.Text>
            <Form.Control plaintext readOnly value={contact.status} />
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Form.Text>Anrede</Form.Text>
                {
                  !editMode ? <Form.Control plaintext readOnly value={contact.anrede} /> :
                <Form.Select name="anrede" readOnly={!editMode} value={contact.anrede} onChange={handleChange} required>
                  <option value="">Bitte wählen</option>
                  <option value="Firma">Firma</option>
                  <option value="Herr">Herr</option>
                  <option value="Frau">Frau</option>
                </Form.Select>
                }
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Form.Text>Name</Form.Text>
            <Form.Control name="name" readOnly={!editMode} plaintext={!editMode} value={contact.name} onChange={handleChange} required/>
          </FormGroup>
        </Col>
        <Col className="mb-4" xs={12} md={6} lg={3}>
          <FormGroup>
            <Form.Text>Adresszusatz</Form.Text>
            <Form.Control name="adresszusatz" readOnly={!editMode} plaintext={!editMode} value={contact.adresszusatz} onChange={handleChange} placeholder="c/o ..."/>
          </FormGroup>
          <FormGroup>
            <Form.Text>Anschrift</Form.Text>
            <Form.Control name="anschrift" readOnly={!editMode} plaintext={!editMode} value={contact.anschrift} onChange={handleChange} required />
          </FormGroup>
          <Row>
            <Col xs={4}>
              <FormGroup>
                <Form.Text>PLZ</Form.Text>
                <Form.Control name="PLZ" readOnly={!editMode} style={(editMode) ? {paddingRight:0}:{}} plaintext={!editMode} value={contact.PLZ} pattern="\d{5}" onChange={handleChange} minLength={5} maxLength={5} required />
              </FormGroup>
            </Col><Col>
              <FormGroup>
                <Form.Text>Ort</Form.Text>
                <Form.Control name="ort" readOnly={!editMode} plaintext={!editMode} value={contact.ort} onChange={handleChange} required />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col className="mb-4" xs={12} md={6} lg={3}>
          <FormGroup>
            <Form.Text>Telefon (Zentrale)</Form.Text>
            <Form.Control name="telefon" readOnly={!editMode} plaintext={!editMode} value={contact.telefon} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Form.Text>E-Mail (Zentrale)</Form.Text>
            <Form.Control name="email" readOnly={!editMode} plaintext={!editMode} value={contact.email} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Form.Text>Webseite</Form.Text>
            <Form.Control name="webseite" readOnly={!editMode} plaintext={!editMode} value={contact.webseite} onChange={handleChange} />
          </FormGroup>
        </Col>
        <Col className="mb-4" xs={12} md={6} lg={3}>
          <FormGroup>
            <Form.Text>Mitarbeiter</Form.Text>
            <Form.Control name="mitarbeiter" readOnly={!editMode} plaintext={!editMode} value={contact.mitarbeiter} onChange={handleChange} pattern="\d*"/>
          </FormGroup>
          <FormGroup>
            <Form.Text>Branche</Form.Text>
            {
              !editMode ? <Form.Control plaintext readOnly value={contact.branche} /> :
            <Form.Select name="branche" readOnly={!editMode} value={contact.branche} onChange={handleChange}>
              <option value="">Bitte wählen</option>
              {
                branchen.map((value, index) => <option key={index} value={value}>{value}</option>)
              }
              <option value="Bauhandwerk">Bauhandwerk</option>
              <option value="Handwerk">Handwerk</option>
              <option value="Industrie">Industrie</option>
              <option value="Handel">Handel</option>
              <option value="Dienstleistung">Dienstleistung</option>
              <option value="Sonstige">Sonstige</option>
            </Form.Select>
            }
          </FormGroup>
          <FormGroup>
            <Form.Text>Erstkontakt</Form.Text>
            {
              !editMode ? <Form.Control plaintext readOnly value={contact.erstkontakt} /> :
            <Form.Select name="erstkontakt" readOnly={!editMode} value={contact.erstkontakt} onChange={handleChange}>
              <option value="">Bitte wählen</option>
              {
                quellen.map((value, index) => <option key={index} value={value}>{value}</option>)
              }
            </Form.Select>}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
        {
          (!editMode && !contact.online) && (
          (contact.status === "Aktiv" ? <Button variant="primary" className="mx-4" disabled={contact?.online} onClick={() => setHandleContract(true)}>Vertrag anzeigen</Button>:
          <Button variant="primary" className="mx-4" disabled={contact?.online} onClick={() => setHandleContract(true)}>Vertrag anlegen</Button>)
          )
        }
        </Col>
      <Col align="end">
        {
          (editMode) ? <ButtonGroup>
            {
              (showMode === "add") ? <Button onClick={handleReset} disabled={!isDirty} variant="outline-primary">Verwerfen</Button> :
              (isDirty) ? <Button onClick={handleReset} variant="outline-primary">Verwerfen</Button> :
              <Button onClick={() => setMode("detail")} variant="outline-primary">Abbrechen</Button>
            } 
            <Button type="submit" disabled={!isDirty}>Speichern</Button>
          </ButtonGroup> :
          <>
          <ButtonGroup>
            { (!contact?.online) && <Button onClick={() => setPage("history") } variant="outline-primary">Historie</Button> }
            { (!contact?.online) && <Button onClick={() => setMode("edit")} variant="outline-primary">Bearbeiten</Button> }
            <Button onClick={closeModal}>Schließen</Button>
          </ButtonGroup></>
        }
        
      </Col>
      </Row>
      </Form>
      {
          (objId !== null && !editMode && !contact?.online) &&
      <Row className="mt-3">
        <hr/>
        <Col xs={12} md={6}>
          <Visits contactId={objId} />
        </Col>
        <Col xs={12} md={6}>
          <PersonsList contactId={objId} />
        </Col>
      </Row>
      }
    </>
  )
}

export default MultiTool