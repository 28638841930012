const types = {
  AUTH_SIGNIN: "AUTH_SIGNIN",
  AUTH_SIGNOUT: "AUTH_SIGNOUT",

  FIREBASE_CONNECTED: "FIREBASE_CONNECTED",
  FIREBASE_ERROR: "FIREBASE_ERROR",

  LOAD_CONTACTS_FAILURE: "LOAD_CONTACTS_FAILURE",
  LOAD_CONTACTS_SUCCESS: "LOAD_CONTACTS_SUCCESS",

  SELECT_CONTACT: "SELECT_CONTACT",



  LOAD_TEAM_SUCCESS: "LOAD_TEAM_SUCCESS",
  LOAD_TEAM_FAILURE: "LOAD_TEAM_FAILURE",
  ADD_TEAM: "ADD_TEAM",

  LOAD_AREAS_SUCCESS: "LOAD_AREAS_SUCCESS",
  LOAD_AREAS_FAILURE: "LOAD_AREAS_FAILURE",
  
  LOAD_ZIP_SUCCESS: "LOAD_ZIP_SUCCESS",
  LOAD_ZIP_FAILURE: "LOAD_ZIP_FAILURE",

  LOAD_PRODUCTS_SUCCESS: "LOAD_PRODUCTS_SUCCESS",
  LOAD_PRODUCTS_FAILURE: "LOAD_PRODUCTS_FAILURE",

  SELECT_TEAM_USER: "SELECT_TEAM_USER",

  MODAL_TOGGLE: "MODAL_TOGGLE",
}
export default types;