import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import FormGroup from "react-bootstrap/FormGroup";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Error from "../../modals/Error";
import Loading from "../../modals/Loading";
import { get, ref, getDatabase } from "firebase/database";
import { loader } from "../../../root/redux/actions/database/loader";

const Commission = () => {

  const today = new Date();
  
  const { uid, tokens } = useSelector(state => state.auth);
  const {team, teamLoaded, teamError, settings} = useSelector(state => state.app);

  const nof = settings.nof;
  
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth() + 1);
  const [user, setUser] = useState(uid);
  const [showYear, setShowYear] = useState(today.getFullYear());
  const [showMonth, setShowMonth] = useState(today.getMonth() + 1);
  const [showUser, setShowUser] = useState(uid);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState({});

  const dispatch = useDispatch();

  const monthNames = [
    null,
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  const getYears = () => {
    let list = [];
    for (let i = 2023; i <= today.getFullYear(); i++) {
      list.push(i);
    }
    return list;
  }

  const getMonths = () => {
    let list = [];
    const max = (parseInt(year) === today.getFullYear() ? today.getMonth() + 1 : 12);
    for (let i = 1; i <= max; i++) {
      list.push([i, monthNames[i]]);
    }
    return list;
  }
  const [monthList, setMonthList] = useState(getMonths());

  useEffect(() => {
    if(parseInt(year) === today.getFullYear()) {
      setMonth(today.getMonth() + 1);
    }
    setMonthList(getMonths());
    // eslint-disable-next-line
  }, [year]);

  const handleRefresh = (force = false) => {
    if(parseInt(month) === parseInt(showMonth) && parseInt(year) === parseInt(showYear) && user === showUser && !force) return;
    setLoading(true);
    const db = getDatabase();
    get(ref(db, "privateStats/" + user + "/" + year + "/" + month))
    .then((result) => {
      setError(false);
      setLoading(false);
      setShowMonth(month);
      setShowYear(year);
      setShowUser(user);
      if (result.exists()) {
        setData(result.exportVal());
      } else {
        setData({});
      }
    })
    .catch((err) => {
      console.log(err);
      setError(true);
      setLoading(false);
      setData({});
    });
  };

  useEffect(() => {
    if(!teamLoaded) {
      dispatch(loader("team"));
    }
    if(teamLoaded) {
      handleRefresh(true);
    }
    // eslint-disable-next-line
  }, [teamLoaded]);

  if(teamError !== null || error) {
    return <Error />;
  }
  else if(!teamLoaded || loading) {
    return <Loading />;
  }

  const teamFiltered = () => {
    let list = [];
    for ( const member in team) {
      list.push([member, team[member].name, team[member].vorname]);
    }
    list.sort((a, b) => a[1].localeCompare(b[1]));
    return list;
  }

  const getSum = () => {
    let amount = 0;
    let newAmount = 0;
    for (const line in data) {
      amount = amount + (data[line].amount || 0);
      if(data[line].first) {
        newAmount = newAmount + (data[line].amount || 0);
      }
    }
    return {
      amount,
      newAmount: newAmount - 3000,
      commission: amount * 0.1,
      closeBonus: newAmount * 0.2,
      topBonus: (newAmount - 3000) * 0.125
    };
  }
  const sum = getSum();

  const renderDate = date => {
    if(date === undefined || date === null || date === "") return "";
    const timestamp = new Date(date);
    return (timestamp.getDate()).toString().padStart(2,0) + '.' + (timestamp.getMonth() + 1).toString().padStart(2,0) + "." + timestamp.getFullYear();
  }

  return (
    <Card.Body>
      <Row>
        <Col xs={(tokens.role === "ceo" || tokens.role === "cmo" || tokens.role === "cso")? 12 : 8} md={(tokens.role === "ceo" || tokens.role === "cmo" || tokens.role === "cso")? 8 : 5}>
          <FormGroup>
            <Form.Text>Zeitraum</Form.Text>
            <InputGroup>
              <Form.Select value={month} onChange={e => setMonth(e.target.value)}>
                {
                  monthList.map(([key, name], index) => <option key={index} value={key}>{name}</option>)
                }
              </Form.Select>
              <Form.Select value={year} onChange={e => setYear(e.target.value)}>
                {
                  getYears().map((value, index) => <option key={index} value={value}>{value}</option>)
                }
              </Form.Select>
              { (tokens.role === "ceo" || tokens.role === "cmo" || tokens.role === "cso") &&
                <Form.Select value={user} onChange={e => setUser(e.target.value)} >
                  {
                    teamFiltered().map(([id, name, vorname]) => <option key={id} value={id}>{name + ", " + vorname}</option>)
                  }
                </Form.Select>
              }
              <Button
                variant={(parseInt(year) === parseInt(showYear) && parseInt(month) === parseInt(showMonth) &&  user === showUser) ? "outline-primary" : "primary"}
                onClick={handleRefresh}
                disabled={(parseInt(year) === parseInt(showYear) && parseInt(month) === parseInt(showMonth) &&  user === showUser)}
                >Aktualisieren</Button>
          </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <hr/>
      <h4 className="my-5">Provisionsabrechnung für{ (showUser !== uid) ? " " + team[showUser].displayName + "," : "" } { monthNames[showMonth] } { showYear }</h4>
      
      {
        (Object.keys(data).length === 0 ) ? <Alert>Keine Daten vorhanden</Alert> :
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Pos.</th>
            <th>Kunde</th>
            <th>PLZ, Ort</th>
            <th>Datum</th>
            <th style={{textAlign:"right"}}>Umsatz (€)</th>
            <th style={{textAlign:"right"}}>Umsatz-<br/>Provision (€)</th>
            <th style={{textAlign:"right"}}>Abschluss-<br/>Provision (€)</th>
          </tr>
        </thead>
        <tbody>
        {
          Object.values(data).map((line, index) => <tr key={index}>
            <td>{ (index + 1).toString().padStart(3,0)  }</td>
            <td>{line?.client}</td>
            <td>{line?.direction}</td>
            <td>{ renderDate(line.timestamp) }</td>
            <td align="end">{ line.amount.toLocaleString("de-DE", nof) }</td>
            <td align="end">{ (line.amount * 0.1).toLocaleString("de-DE", nof) }</td>
            <td align="end">{ (line?.first) ? (line.amount * 0.2).toLocaleString("de-DE", nof) : "0,00" }</td>
          </tr>)
        }
        </tbody>
        <tfoot>
          <tr style={{borderTopWidth: "3px"}} className="fw-semibold">
            <td colSpan={4} align="end"></td>
            <td align="end">{ sum.amount.toLocaleString("de-DE", nof) }</td>
            <td align="end">{ sum.commission.toLocaleString("de-DE", nof) }</td>
            <td align="end" >{ sum.closeBonus.toLocaleString("de-DE", nof) }</td>
          </tr>
          <tr>
            <td colSpan={7}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={6} align="end">Umsatzprovision auf € { sum.amount.toLocaleString("de-DE", nof) }</td>
            <td align="end">€ { sum.commission.toLocaleString("de-DE", {...nof, minimumFractionDigits: 3, maximumFractionDigits: 3}) }</td>
          </tr>
          <tr>
            <td colSpan={6} align="end">Neukundenumsatz auf € { (sum.newAmount + 3000).toLocaleString("de-DE", nof) }</td>
            <td align="end">€ { sum.closeBonus.toLocaleString("de-DE", {...nof, minimumFractionDigits: 3, maximumFractionDigits: 3}) }</td>{ /*
              Bonus Prämie {(sum.newAmount + 3000).toLocaleString("de-DE", nof) } - 3.000,00 = {(sum.newAmount).toLocaleString("de-DE", nof) }
            </td>
            <td align="end">
              + { sum.topBonus.toLocaleString("de-DE", nof) }
    </td> */}
          </tr>
          
            <tr>
              <td colSpan={6} align="end">Ü3000-Neukundenprämie auf € { (sum.newAmount).toLocaleString("de-DE", nof) }</td>
              <td align="end">€ { (sum.newAmount > 0) ? sum.topBonus.toLocaleString("de-DE", {...nof, minimumFractionDigits: 3, maximumFractionDigits: 3}) : "0,00"}</td>
            </tr>
          
          <tr>
            <td colSpan={6} align="end">Netto Gesamtbetrag:</td>
            <td colSpan={1} align="end"  style={{borderTopColor:"#000", borderTopWidth:"2px"}}>
              € { (sum.commission + sum.closeBonus + ((sum.newAmount > 0) ? sum.topBonus : 0)).toLocaleString("de-DE", nof) }
            </td>
          </tr>
          <tr>
            <td colSpan={6} align="end">Umsatzsteuer (19%):</td>
            <td colSpan={1} align="end">
              € { ((sum.commission + sum.closeBonus + ((sum.newAmount > 0) ? sum.topBonus : 0))*0.19).toLocaleString("de-DE", nof) }
            </td>
          </tr>
          <tr className="fw-semibold">
            <td colSpan={6} align="end"  className="fw-semibold">Brutto Auszahlungsbetrag (inkl. 19% USt.):</td>
            <td colSpan={1} align="end"  style={{borderTopColor:"#000", borderTopWidth:"2px", borderBottom: "4px double #000"}}>
              € { ((sum.commission + sum.closeBonus + ((sum.newAmount > 0) ? sum.topBonus : 0))*1.19).toLocaleString("de-DE", nof) }
            </td>
          </tr>
        </tfoot>
      </Table>
      }
    </Card.Body>
  )
}

export default Commission