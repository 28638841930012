import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../root/redux/actions/database/loader";
import {  BsSortAlphaDown, BsSortAlphaUp } from "react-icons/bs";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Error from "../../modals/Error";
import Loading from "../../modals/Loading";

const FilterAndSort = ({ q = null, status = null, modalAdd, modalDetail}) => {
  const dispatch = useDispatch();
  const { team, teamLoaded, teamError, areas, areasLoaded, areasError } = useSelector(state => state.app);
  const [sortedTeam, setSortedTeam] = useState([]);
  const [sortProperty, setSortProperty] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const pageSize = 25;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSort = (property) => {
    if (property === sortProperty) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortProperty(property);
      setSortOrder("asc");
    }
  };
  
  useEffect(() => {
    if (!teamLoaded) {
      dispatch(loader("team"));
    }
    if (!areasLoaded) {
      dispatch(loader("areas"));
    }
  }, [teamLoaded, areasLoaded, dispatch]);

  useEffect(() => {
    if(team !== null) {
    let sorted = Object.entries(team).sort(([, a], [, b]) => {
      if (a && b) {
        const xA = a.name || "";
        const xB = b.name || "";
        return xA.localeCompare(xB);
      }
      return 0;
    });

    if (sortProperty) {
      sorted.sort(([, a], [, b]) => {
        const valueA = a && a[sortProperty] ? a[sortProperty].toString().toLowerCase() : "";
        const valueB = b && b[sortProperty] ? b[sortProperty].toString().toLowerCase() : "";

        if (sortOrder === "asc") {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      });
    }
    if(status) {
      sorted = sorted.filter(([_, team]) => {
        if(status === "Alle") {
          return true;
        }
        else if (team.hasOwnProperty("role") && team.role === status) {
          return true;
        }
        return false;
      });
    }
    if (q) {
      sorted = sorted.filter(([, team]) => {
        const searchQuery = q
          .toLowerCase()
          .replace(/[^a-z0-9]/gi, "")
          .replace(/strasse|straße|str./g, "str");
        
        const name = team.name || "";
        const vorname = team.vorname || "";
        const displayName = team.displayName || "";
        const address = team.anschrift || "";
        const zipCodeAndCity = (team.PLZ || "") + " " + (team.ort || "");

        return (
          name.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          vorname.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          displayName.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          address.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          zipCodeAndCity.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery)
        );
      });
    }

    setSortedTeam(sorted);
  }
  }, [team, sortProperty, sortOrder, q, status]);

  if (teamError !== null || areasError !== null) {
    return <Error />;
  }

  if (!teamLoaded || !areasLoaded) {
    return <Loading />;
  }

  const totalTeam = sortedTeam.length;
  const totalPages = Math.ceil(totalTeam / pageSize);
  const teamToShow = sortedTeam.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const renderTooltip = (title) => (
    <Tooltip id="button-tooltip" placement="top" delay={{ show: 250, hide: 400 }}>
      {title}
    </Tooltip>
  );
  if (team === null) {
    return (
      <Alert variant="primary">
        Sie haben noch keine Kontakte, legen Sie jetzt eine an.
        <br />
        <br />
        <Button variant="primary" onClick={modalAdd}>
          Neuer Kontakt
        </Button>
      </Alert>
    );
  }
  else if (teamToShow.length === 0) {
    return (
      <Alert variant="primary">
        Es gibt keine Übereinstimmungen.
      </Alert>
    );
  }
  /*
  const getAreaUser = (zipCode, show = "district") => {
    if(zipCode === null) return "";
    const zC = zipCode.substring(0,3);
    for (const item in zip) {
      if(zip[item].zip === zC) {
          if (show==="region") return teamName(areas[zip[item]?.region]?.user);
          else if (show==="area") return teamName(areas[zip[item]?.area]?.user);
          else return teamName(areas[zip[item]?.district]?.user);
      };
    }
  }
  const getAreaName = (zipCode, show = "district") => {
    if(zipCode === null) return "";
    const zC = zipCode.substring(0,3);
    for (const item in zip) {
      if(zip[item].zip === zC) {
          if (show==="region") return areas[zip[item]?.region]?.name;
          else if (show==="area") return areas[zip[item]?.area]?.name;
          else return areas[zip[item]?.district].name;
      };
    }
  } */
  const getArea = (uid) => {
    for ( const area in areas ) {
      if(areas[area]?.user && areas[area].user === uid) {
        const areaType = areas[area].type;
        if(areaType === "district") {
          return (<td valign="middle">{ areas[areas[area].region].name } &gt; {areas[areas[area].area].name} &gt; { areas[area].name }</td>);
        }
        else if(areaType === "area") {
          return (<td valign="middle">{ areas[areas[area].region].name } &gt; {areas[areas[area].area].name}</td>);
        }
        else if(areaType === "area") {
          return (<td valign="middle">{ areas[areas[area].region].name } &gt; {areas[areas[area].area].name}</td>);
        }
        else if(areaType === "region") {
          return (<td valign="middle">{ areas[areas[area].region].name }</td>);
        }
        return (<td valign="middle">{ areas[area].name }</td>);
      }
    }
    return (<td valign="middle"></td>);
  }
    return (
      <>
        <Table striped hover>
          <thead>
            <tr>
              <th onClick={() => handleSort("role")} style={{cursor:"pointer"}}>
                  Funktion {sortProperty === "role" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
              </th>
              <th onClick={() => handleSort("name")} style={{cursor:"pointer"}}>
                  Name {sortProperty === "name" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
              </th>
              <th>
                Region &gt; Gebiet &gt; Bezirk
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {teamToShow.map(([key, team], index) => (
              <tr key={index}>
                <td valign="middle" style={{fontWeight:600, textTransform: "uppercase"}}>{ team?.role || "" }</td>
                <td valign="middle">{ (team?.vorname ? team.vorname.substring(0,1) + ". " : "" ) + team?.name || "" }</td>
                { getArea(key) }
                <td valign="middle" align="end">
                  <OverlayTrigger overlay={renderTooltip("Details öffnen")}>
                    <Button onClick={() => modalDetail({ id: key, el: team })} variant="outline-primary" size="sm">
                      Details
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination className="text-center">
          {
            (currentPage > 1) && <>
              <Pagination.First onClick={() => handlePageChange(1)}/>
              <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}/>
            </>
          }
          {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Pagination.Item>
          ))}
          {
            (currentPage < totalPages) && <>
              <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}/>
              <Pagination.Last onClick={() => handlePageChange(totalPages)}/>
            </>
          }
          </Pagination>
        </div>
      </>
    );

 
};

export default FilterAndSort;
