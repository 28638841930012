export const defaultData = {
  contactId: "",
  anrede: "",
  vorname: "",
  name: "",
  geburtstag: "",
  funktion: "",
  mobil: "",
  telefon: "",
  email: "",
  notizen: "",
};