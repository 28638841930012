import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Button from "react-bootstrap/Button";
import { useEffect, useRef, useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const Password = ({toggle}) => {

  const emailRef = useRef(null);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    setValidated(true);
    console.log("Done");
    sendPasswordResetEmail(getAuth(), emailRef.current.value)
    .then(() => toggle(true))
    .catch(() => console.log("etwas ist schief gelaufen"));
  };

  

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  return (
    <Container>
      <div className="auth">
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <h1 className="fs-2">Neues Passwort</h1>
          <FormGroup className="mt-3">
            <Form.Text>E-Mail</Form.Text>
            <Form.Control type="email" id="email" required ref={emailRef} />
            <Form.Control.Feedback type="invalid">
              E-Mail Adresse ist ungültig
            </Form.Control.Feedback>
          </FormGroup>
          <div className="d-grid gap-3 mt-3">
            <Button variant="link" onClick={() => toggle(true)}>Zurück zum Login</Button>
            <Button type="submit">Zurücksetzen</Button>
          </div>
        </Form>
      </div>
    </Container>
  )
}

export default Password