import { useState } from "react";
import Login from "./Login";
import Password from "./Password";
import "./auth.css";

const Auth = () => {

  const [login, setLogin] = useState(true);

  if(login) return <AuthFooter><Login toggle={setLogin}/></AuthFooter>;
  else return <AuthFooter><Password toggle={setLogin}/></AuthFooter>;
}

const AuthFooter = ({ children }) => {
  return(
  <>
  {children}
  <p className="authFooter">
    <a href="https://www.werkapp.de" target="_blank" rel="noopener noreferrer"><span className="Werk">Werk</span><span className="APP">APP</span></a> Vertriebs GmbH | <a 
    href="https://www.werkapp.de/impressum" target="_blank" rel="noopener noreferrer">Impressum</a> | <a 
    href="https://www.werkapp.de/privacy" target="_blank" rel="noopener noreferrer">Datenschutz</a>
  </p>
  </>
  );
}

export default Auth