function detectChanges(obj1, obj2, ignoredKeys = []) {
  const changes = [];

  for (const key in obj1) {
    if (ignoredKeys.includes(key)) {
      continue;
    }
    if(obj1[key] === null && obj2[key] === null) {
      continue;
    }

    if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
      if (!arraysEqual(obj1[key], obj2[key])) {
        changes.push({
          key,
          before: obj1[key],
          after: obj2[key] || null
        });
      }
    } else if(typeof obj1[key] === "object" && obj1[key] !== null && typeof obj2[key] === "object" && obj2[key] !== null) {
      const obj = detectChanges(obj1[key], obj2[key]);
      const arr = obj.changes;
      for (let i = 0; i < arr.length; i++) {
        if(arr[i].before === arr[i].after || (arr[1].before === null && arr[1].after === null)) {
          continue;
        }
        changes.push({
          key: key + "-" + arr[i].key,
          before: arr[i]?.before || null,
          after: arr[i]?.after || null,
        });
      }
    } else if (obj1[key] !== obj2[key]) {
      changes.push({
        key,
        before: obj1[key],
        after: obj2[key] || null
      });
    }
  }

  for (const key in obj2) {
    if (ignoredKeys.includes(key)) {
      continue;
    }
    if (!(key in obj1) && obj2[key] !== null && typeof obj2[key] !== "object") {
      changes.push({
        key,
        before: null,
        after: obj2[key]
      });
    }
  }

  // Filtere nur die tatsächlich veränderten Werte
  const filteredChanges = changes.filter(change => change.before !== change.after);

  return { changes: filteredChanges };
}

function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    if (!isObjectEqual(obj1, obj2)) {
      return false;
    }
  }

  return true;
}

function isObjectEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}


/* BACKUP
function detectChanges(obj1, obj2, ignoredKeys = []) {
  const changes = [];

  for (const key in obj1) {
    if (ignoredKeys.includes(key)) {
      continue;
    }

    if (obj1[key] !== obj2[key]) {
      changes.push({
        key,
        before: obj1[key],
        after: obj2[key] || null
      });
    }
  }

  for (const key in obj2) {
    if (ignoredKeys.includes(key)) {
      continue;
    }

    if (!(key in obj1) && obj2[key]!==null) {
      changes.push({
        key,
        before: null,
        after: obj2[key]
      });
    }
  }

  return {changes};
}*/

export default detectChanges;