import { equalTo, get, getDatabase, orderByChild, push, query, ref, serverTimestamp } from "firebase/database";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../modals/Loading";
import Error from "../../modals/Error";
import Accordion from "react-bootstrap/Accordion";
import { Button, ButtonGroup, Col, Form, FormGroup, InputGroup, Row } from "react-bootstrap";
import { loader } from "../../../root/redux/actions/database/loader";


const Visits = (props) => {

  const { contactId } = props;
  const uid = useSelector(state => state.auth.uid);
  const {team, teamLoaded, teamError} = useSelector(state => state.app);
  const [visits, setVisits] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [hasError, setError] = useState(false);
  
  const [addNew, setAddNew] = useState(false);


  const dispatch = useDispatch();

  const convertToUnixString = (d, t="11:00") => {
    const today = new Date();
    var date = (d==="" ? today.getDate().padStart(2, 0) + "." + (today.getMonth() +1).padStart(2,0) + "." + today.getFullYear() : d);
    var dateTimeString = date + "T" + t + ":00";
    var dateTime = new Date(dateTimeString);
    return dateTime.getTime();
  }

  const load = () => {
    const db = getDatabase();
    const visitRef = query(ref(db, "visits"),orderByChild("contactId"),equalTo(contactId));
    get(visitRef)
    .then((response) => {
      setLoaded(true);
      const val = response.exportVal();
      if(val !== null) {
        const arr = Object.entries(val);
        arr.sort((a, b) => b[1].timestamp - a[1].timestamp);
        const res = Object.fromEntries(arr);
        setVisits(res);
      }
    })
    .catch((err) => {
      console.log(err);
      setLoaded(true);
      setError(true);
    });
  }

  useEffect(() => {
    if(contactId !== undefined && contactId !== null && !isLoaded) {
      load();
    }
    if(!teamLoaded) {
      dispatch(loader("team"));
    }
    // eslint-disable-next-line
  }, [contactId]);

  if(!isLoaded || !teamLoaded) return <Loading />;

  if(hasError || teamError!==null) return <Error />;

  const formatUnixString = (unixString = null) => {
    if(unixString === null) return "";
    var dateTime = new Date(unixString);
    var day = dateTime.getDate().toString().padStart(2, "0");
    var month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    var year = dateTime.getFullYear();
    var hours = dateTime.getHours().toString().padStart(2, "0");
    var minutes = dateTime.getMinutes().toString().padStart(2, "0");
    var formattedDateTime = day + "." + month + "." + year + " - " + hours + ":" + minutes + " Uhr";
    return formattedDateTime;
  }
  const teamName = id => {
    if (team === null || team === undefined || Object.keys(team).length === 0) return <span style={{opacity:.3}}>leer</span>;
    for (const mate in team) {
      if(team[mate].hasOwnProperty("region") && team[mate].region === id) {
        return team[mate].displayName;
      }
    }
    return <span style={{opacity:.3}}>leer</span>;
  }

  const ListItems = () => {
  return (
    <Accordion>
    {
      (visits !== null && Object.keys(visits).length > 0) && Object.entries(visits).map(([key, visit], index) => <Accordion.Item
      key={key}
      eventKey={index}
      >
        <Accordion.Header>{ formatUnixString(visit?.timestamp) } - {visit.anlass}</Accordion.Header>
        <Accordion.Body>
          <p>{visit.bericht}</p>
          <p className="text-muted m-0" style={{fontSize:"12px"}}>hinzugefügt von { teamName(visit.addedBy) } am { formatUnixString(visit.addedAt) }</p>
        </Accordion.Body>
      </Accordion.Item>)
    }
    </Accordion>
  )
  }

  const NewItem = () => {
    const [validated, setValidated] = useState(false);
    const [isDirty, setDirty] = useState(false);
    const now = new Date();
    const datum = now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2,0) + "-" + now.getDate().toString().padStart(2,0);
    const uhrzeit = now.getHours().toString().padStart(2,0) + ":" + now.getMinutes().toString().padStart(2,0);
    const [newVisit, setNewVisit] = useState({datum: datum, uhrzeit: uhrzeit, anlass: "Besuch", bericht: ""});

    console.log(newVisit);

    const handleVisitChange = e => {
      setDirty(true);
      setNewVisit(prev => ({...prev, [e.target.name] : e.target.value}));
    }
    const handleReset = () => {
      setAddNew(false);
      setNewVisit({datum: datum, uhrzeit: uhrzeit, anlass: "Besuch", bericht: ""});
      setDirty(false);
      setValidated(false);
    }
    const handleSubmit = e => {
      e.preventDefault();
      e.stopPropagation();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        setValidated(true);
        return ;
      }
      const now = new Date();
      const data = {...newVisit, contactId: contactId, addedAt: now.getTime(), addedBy: uid, timestamp: convertToUnixString(newVisit.datum, newVisit.uhrzeit)};
      const db = getDatabase();
      push(ref(db, "visits"), {...data, addedAt: serverTimestamp()})
      .then((res) => {
        setVisits(prev => {
          const arr = Object.entries({...prev, [res]: data});
          arr.sort((a, b) => b[1].timestamp - a[1].timestamp);
          return Object.fromEntries(arr);
        });
        setAddNew(false);
        setDirty(false);
        setValidated(false);
        setNewVisit({datum: datum, uhrzeit: uhrzeit, anlass: "Besuch", bericht: ""});
      })
      .catch((err) => { console.log(err); });
    }

    return (<Form onSubmit={handleSubmit} noValidate validated={validated}>
      <Row>
        <Col xs={12} md={6}>
          <FormGroup className="mb-2">
            <Form.Text>Datum, Uhrzeit</Form.Text>
            <InputGroup>
              <Form.Control type="date" style={{width:"100px"}} name="datum" value={newVisit.datum} onChange={handleVisitChange} required/>
              <Form.Control type="time"  name="uhrzeit" value={newVisit.uhrzeit} onChange={handleVisitChange} required />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Form.Text>Kontaktart</Form.Text>
            <Form.Select name="anlass" value={newVisit.anlass} onChange={handleVisitChange} required>
              <option value="Baustelle">Baustelle</option>
              <option value="Nicht angetroffen">Nicht angetroffen</option>
              <option value="Besuch">Besuch</option>
              <option value="Termin">Termin</option>
              <option value="Telefonat">Telefonat</option>
              <option value="Messe">Messe</option>
              <option value="Andere">Andere</option>
            </Form.Select>
          </FormGroup>
        </Col>
      </Row>  
      <Row>
        <Col xs={12} md={true}>
          <FormGroup>
            <Form.Text>Bericht</Form.Text>
            <Form.Control as="textarea" name="bericht" value={newVisit.bericht} onChange={handleVisitChange} rows="4" required={(newVisit.anlass !== "Nicht angetroffen")} />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col align="end">
          <ButtonGroup>
            <Button variant="outline-primary" onClick={handleReset}>Verwerfen</Button>
            <Button variant="primary" type="submit" disabled={!isDirty}>Speichern</Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Form>);
  }


  return (<>
    <Row className="mb-2">
      <Col>
        <h6 className="fw-semibold">Besuchsberichte</h6>
      </Col>
      <Col align="end">
        {
          !addNew && <Button size="sm" variant="outline-primary" onClick={() => setAddNew(true)}>Neuer Bericht</Button>
        }
      </Col>
    </Row>
      <div style={{maxHeight:"300px",overflowY:"scroll", overflowX: "hidden"}}>
        {
          (addNew) ? <NewItem /> : <ListItems />
        }
      </div>
  </>);
}

export default Visits