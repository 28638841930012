import {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { defaultData, defaultRoles, filterRoles } from "./defaultData";
import { useDispatch, useSelector } from "react-redux";
import { updater } from "../../../root/redux/actions/database/updater";
import { loader } from "../../../root/redux/actions/database/loader";
import Loading from "../../modals/Loading";
import Error from "../../modals/Error";
import { getAuth, updateEmail, updateProfile } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

const MultiTool = ({obj = null, objId = null, mode = "detail", closeModal}) => {
  
  const { areas, areasLoaded, areasError} = useSelector(state => state.app);
  const { uid, tokens } = useSelector(state => state.auth);
  const userRole = tokens.role;
  // State for view modus
  const [showMode, setMode] = useState(mode);
  const [areasDisponible, setAreasDisponible] = useState([]);
  // State for Editing active or not.
  const [editMode, setEdit] = useState(false);
  // Set old data if there is some.
  const [contact, setContact] = useState((obj !== null ? {...defaultData, ...obj}:{...defaultData}));
  // State to see if there are any changes.
  const [isDirty, setDirty] = useState(false);
  // State to validate the form.
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();
  // handle changes in the form.
  const handleChange = e => {
    setDirty(true);
    setContact(prev => ({...prev, [e.target.name]: e.target.value }));
  }
  const handleReset = e => {
    if(window.confirm("Alle Eingaben verwerfen?")) {
      if(showMode === "edit") {
        setContact({...defaultData, ...obj});
        setValidated(false);
        setDirty(false);
        setMode("detail");
      }
      else {
        setContact({...defaultData});
        setValidated(false);
        setDirty(false);
        closeModal();
      }
    }
  }
  const handleSave = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    const teamMember = httpsCallable(getFunctions(), "Sales_AddTeamMember");

    contact.displayName = contact.vorname + " " + contact.name;

    if (obj?.area && obj?.area !== null && obj.area !== "") {
      if (contact.area !== null && contact.area !== "" && contact.area !== obj.area) {
        if (contact.role === "hv" || contact.role === "adm" || contact.role === "gvl" || contact.role === "rvl") {
          updater("areas", {user: objId}, contact.area);
        }
      }
    }

    if (showMode === "edit" && objId !== null) {
      if (uid === objId) {
        // User change its own Profile Options
        if (!obj?.displayname || obj.displayName !== contact.displayName) {
          updateProfile(getAuth().currentUser, {displayName: contact.displayName})
          .catch((error) => { console.log(error); });
        }
        if (obj.email !== contact.email) {
          updateEmail(getAuth().currentUser, {email: contact.email})
          .catch((error) => { console.log(error); });
        }
      }
      else {
      // Changing other User
        if (!obj?.displayname || obj.displayName !== contact.displayName) {
          teamMember({email: contact.email, displayName: contact.displayName});
        }
        if (obj.email !== contact.email) {
          alert("E-Mail Adresse kann nicht von dritten geändert werden!");
        }
      }
      updater("team", contact, objId, obj);
    }
    if(showMode === "add") {
      teamMember(contact)
      .then((response) => {
        const uid = response.data.key;
        updater("team", contact, uid);
        if(contact.area !== "" && contact?.area && contact.area !== null) {
          updater("areas", {user: uid}, contact.area);
        }
      });
    }
    setContact(contact)
    setDirty(false);
    setValidated(false);
    setMode("detail");
  }
  const blockUser = () => {
    // call removePrevilegs
  }
  const deleteUser = () => {
    // call userDelete
  }
  const unblockUser = () => {
    // call add previlegs
  }

  useEffect(() => {
    setEdit((showMode === "edit" || showMode === "add"));
  }, [showMode, mode]);

  useEffect(() => {
    if(!areasLoaded) {
      dispatch(loader("areas"));
    }
  }, [dispatch, areasLoaded]);


  const areaSelect = (role) => {
    let list = [];
    for (const item in areas) {
      if((role === "hv" || role === "adm") && areas[item].type === "district" && areas[item]?.user === "") {
        list.push({name: areas[item].name, id: item});
      }
      else if(role === "gvl" && areas[item].type === "area" && areas[item]?.user === "") {
        list.push({name: areas[item].name, id: item});
      }
      else if(role === "rvl" && areas[item].type === "region" && areas[item]?.user === "") {
        list.push({name: areas[item].name, id: item});
      }
    }
    return list;
  }
  useEffect(() => {
    setAreasDisponible(areaSelect(contact.role));
    // eslint-disable-next-line
  }, [contact.role]);

  if(!areasLoaded) return <Loading />;
  if(areasError !== null) return <Error />;

  const getContactRoleName = (role) => {
    for (let i = 0; i < filterRoles.length; i++) {
      if (filterRoles[i][0] === role) {
        return filterRoles[i][1];
      }
    }
    return null;
  }

  return (
    <Form onSubmit={handleSave} noValidate validated={validated}>
      <Row>
        <Col className="mb-4" xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Text>Anrede</Form.Text>
            {
              !editMode ? <Form.Control plaintext readOnly value={contact.anrede} /> :
            <Form.Select name="anrede" readOnly={!editMode} value={contact.anrede} onChange={handleChange} required>
              <option value="">Bitte wählen</option>
              <option value="Herr">Herr</option>
              <option value="Frau">Frau</option>
            </Form.Select>
            }
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Form.Text>Vorname</Form.Text>
                <Form.Control name="vorname" readOnly={!editMode} plaintext={!editMode} value={contact.vorname} onChange={handleChange} required/>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Nachname</Form.Text>
                <Form.Control name="name" readOnly={!editMode} plaintext={!editMode} value={contact.name} onChange={handleChange} required/>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Form.Text>ggf. Unternehmensname</Form.Text>
            <Form.Control name="firma" readOnly={!editMode} plaintext={!editMode} value={contact.firma} onChange={handleChange}/>
          </FormGroup>
        </Col>
        <Col className="mb-4" xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Text>Mobil</Form.Text>
            <Form.Control type="phone" name="mobil" readOnly={!editMode} plaintext={!editMode} value={contact.mobil} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Form.Text>Telefon</Form.Text>
            <Form.Control type="phone" name="telefon" readOnly={!editMode} plaintext={!editMode} value={contact.telefon} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Form.Text>E-Mail</Form.Text>
            <Form.Control type="email" name="email" readOnly={!editMode} plaintext={!editMode} value={contact.email} onChange={handleChange} />
          </FormGroup>
        </Col>
        <Col className="mb-4" xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Text>Funktion</Form.Text>
            {
              !editMode ? <Form.Control plaintext readOnly value={getContactRoleName(contact.role) }/> :
              <Form.Select name="role" value={contact.role} readOnly={!editMode} plaintext={!editMode} onChange={handleChange} >
                {
                  defaultRoles.map(([key, value]) => <option value={key} key={key}>{value}</option>)
                }
              </Form.Select>
            }
          </FormGroup>
          {
            (contact.role !== "ceo" && contact.role !== "cso" && contact.role !== "cmo") &&
            <FormGroup>
              <Form.Text>
                {
                  (contact.role === "hv" || contact.role === "adm") && "Bezirk"
                }
                {
                  (contact.role === "gvl") && "Gebiet"
                }
                {
                  (contact.role === "rvl") && "Region"
                }
              </Form.Text>
              {
                !editMode ? <Form.Control plaintext readOnly value={(contact?.area && contact.area !== "") ? areas[contact.area].name : ""} /> :
              <Form.Select name="area" readOnly={!editMode} value={contact.area} plaintext={!editMode} onChange={handleChange}>
                <option value="">Kein Gebiet zugewiesen</option>
                {
                  areasDisponible.map((obj, index) => <option value={obj.id} key={index}>{obj.name}</option>)
                }
              </Form.Select>
              }
            </FormGroup>
          }
        </Col>
      </Row>
      <Row className="mt-4">
      <Col align="end">
        {
          (userRole === "ceo" || userRole === "cmo" || userRole === "cso") &&
            ((contact.role === "blocked" || contact.role === "deleted") ?
            <ButtonGroup>
              <Button variant="outline-danger" style={{marginRight:"15px"}} onClick={unblockUser}>Entsperren</Button>
              <Button variant="outline-danger" style={{marginRight:"15px"}} onClick={deleteUser}>Endgültig löschen</Button>
            </ButtonGroup>:
            <Button variant="outline-danger" style={{marginRight:"15px"}} onClick={blockUser}>Zugang sperren</Button>)
        }
        {
          (editMode) ? <ButtonGroup>
            {
              (showMode === "add") ? <Button onClick={handleReset} disabled={!isDirty} variant="outline-primary">Verwerfen</Button> :
              (isDirty) ? <Button onClick={handleReset} variant="outline-primary">Verwerfen</Button> :
              <Button onClick={() => setMode("detail")} variant="outline-primary">Abbrechen</Button>
            } 
            <Button type="submit" disabled={!isDirty}>Speichern</Button>
          </ButtonGroup> :
          <>
          <ButtonGroup>
            { (objId === uid || userRole === "cso" || userRole === "ceo" ||
              ((contact.role === "adm" || contact.role === "hv") && (userRole === "gvl" || userRole === "rvl")) ||
              ((contact.role === "gvl") && (userRole === "rvl"))) && <Button onClick={() => setMode("edit")} variant="outline-primary">Bearbeiten</Button> }
            <Button onClick={closeModal}>Schließen</Button>
          </ButtonGroup></>
        }
        
      </Col>
    </Row>
    </Form>
  )
}

export default MultiTool