import { getAuth } from "firebase/auth";
import { get, getDatabase, ref, update } from "firebase/database";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Error from "../../modals/Error";
import Loading from "../../modals/Loading";

const Payment = () => {

  const defaultData = {iban: "", accountHolder: "", taxNumber: "", vatNumber: ""};
  const { uid } = getAuth().currentUser;
  const [data, setData] = useState(defaultData);
  const [loadedData, setLoadedData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [edit, setEdit] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleChange = e => {
    if(e.target.name === "iban") {
      const value = e.target.value.toUpperCase().replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      setData(prev => ({...prev, [e.target.name]: value}));
    }
    else setData(prev => ({...prev, [e.target.name]: e.target.value}));
  }
  const handleReset = () => {
    setData({...defaultData, ...loadedData});
    setEdit(false);
  }
  const handleSave = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    setValidated(false);
    update(ref(getDatabase(), "privateStats/" + uid + "/paymentMethod"), {...data})
    .then(() => {
      setEdit(false);
      setError(false);
      setLoadedData({...data});
    })
    .catch((error) => {
      console.log(error);
      setError(true);
    })
  }

  useEffect(() => {
    get(ref(getDatabase(), "privateStats/" + uid + "/paymentMethod"))
    .then((result) => {
      setData({...defaultData, ...result.exportVal()});
      setLoadedData(result.exportVal());
      setLoading(false);
      setError(false);
    })
    .catch((error) => {
      console.log(error);
      setError(true);
    })
    // eslint-disable-next-line
  }, []);

  if(error) return <Card.Body><Error /></Card.Body>;
  else if(loading) return <Card.Body><Loading /></Card.Body>;

  return (
    <Card.Body>
      <h4>Deine Zahlungsdaten</h4>
      <Form onSubmit={handleSave} noValidate validated={validated}>
        <p className="my-3">Bitte hinterlege hier deine Bank- und Steuerdaten.<br/>Nur wenn diese Daten vollständig sind, können wir Deine Provision schnell und automatisch überweisen.</p>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3">
              <Form.Text>Kontoinhaber</Form.Text>
              <Form.Control value={data.accountHolder} plaintext={!edit} readOnly={!edit} name="accountHolder" onChange={handleChange} required/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Text>IBAN</Form.Text>
              <Form.Control value={data.iban} plaintext={!edit} readOnly={!edit} name="iban" onChange={handleChange} style={{textTransform:"uppercase"}} required/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Text>Steuernummer</Form.Text>
              <Form.Control value={data.taxNumber} plaintext={!edit} readOnly={!edit} name="taxNumber" onChange={handleChange} required/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Text>Umsatzsteuer ID (freiwillig)</Form.Text>
              <Form.Control value={data.vatNumber} plaintext={!edit} readOnly={!edit} name="vatNumber" onChange={handleChange} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col align="end">
            {
              edit ? <ButtonGroup>
                <Button variant="outline-primary" onClick={handleReset}>Änderungen verwerfen</Button>
                <Button type="submit">Speichern</Button>
              </ButtonGroup> :
              <Button variant="outline-primary" onClick={() => setEdit(true)}>Bearbeiten</Button>
            }
            
          </Col>
        </Row>
      </Form>
    </Card.Body>
  )
}

export default Payment;