import { useEffect, useState } from "react";
import Loading from "../../modals/Loading";
import Error from "../../modals/Error";
import { equalTo, get, getDatabase, orderByChild, query, ref } from "firebase/database";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

const ExistingContract = ({obj = {}, objId = null, goBack}) => {

  const [persons, setPersons] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    get(query(ref(getDatabase(), "persons"),orderByChild("contactId"),equalTo(objId)))
    .then((result) => {
      if(result !== null) {
        setPersons(result.exportVal());
      }
      setLoading(false);
    })
    .catch((err) => {
      console.error(err);
      setError(true);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  if(loading) {
    return <Loading />;
  }
  if(hasError) {
    return <Error />;
  }
  console.log(persons);

  return (
    <>
    <h5>Vertragsmanagment</h5>
    <p>Aktueller Status: { (obj?.status && obj?.status !== "Kontakt") ? obj?.status : "Kein Vertrag"}</p>
    <Alert>Das Vertragsmanagment wird derzeit überarbeitet und steht in Kürze zur Verfügung.<br/><br/>
    <Button onClick={goBack}>Zurück</Button></Alert>
    { /*
      Object.keys(obj?.licenses).length > 0 &&
      Object.entries(obj.licenses).map(([personKey, value], index) => <p key={index}>
        {persons[personKey].displayName} - { JSON.stringify(value) }
      </p>)
    */ }
    </>
  )
}

export default ExistingContract