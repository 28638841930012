import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { loader } from "../../../root/redux/actions/database/loader";
import Loading from "../../modals/Loading";
import Error from "../../modals/Error";
import Country from "./Country";
import District from "./District";
import Area from "./Area";
import Region from "./Region";
import { useParams } from "react-router-dom";


const Areas = () => {

  const { areas, areasLoaded, zipLoaded, areasError, zipError } = useSelector(state => state.app);
  const { tokens } = useSelector(state => state.auth);
  const { country = null, region = null, area = null, district = null} = useParams();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if(!areasLoaded) {
      dispatch(loader("areas"));
    }
    if(!zipLoaded) {
      dispatch(loader("zip"));
    }
    // eslint-disable-next-line
  }, [dispatch, areasLoaded, zipLoaded]);

  if(areasError !== null || zipError !== null) return <Error />;

  if(!areasLoaded || !zipLoaded) return <Loading />;


  const FindArea = () => {
    if(tokens.role === "ceo" || tokens.role === "cso") {
      if(district !== null) {
        return <District id={district} />;
      }
      else if (area !== null) {
        return <Area id={area} />;
      }
      else if (region !== null) {
        return <Region id={region} />;
      }
      else if (country !== null) {
        return <Country id={country} />;
      }
      else return <Country id={null} />;
    }
    if(tokens.hasOwnProperty("area") && areas.hasOwnProperty(tokens?.area)) {
      const typeOfArea = areas[tokens.area].type;

      if(typeOfArea === "country") {
        // Landesweiter Zugriff
        if(district !== null && areas[district].country === tokens.area) {
          return <District id={district} />;
        }
        else if (area !== null && areas[area].country === tokens.area) {
          return <Area id={area} />;
        }
        else if (region !== null && areas[region].country === tokens.area) {
          return <Region id={region} />;
        }
        return <Country id={tokens.area} />;
      }
      else if (typeOfArea === "region") {
        // Region Zugriff
        if(district !== null && areas[district].region === tokens.area) {
          return <District id={district} />;
        }
        else if (area !== null && areas[area].region === tokens.area) {
          
          return <Area id={area} />;
        }
        return <Region id={tokens.area} />;
      }
      else if (typeOfArea === "area") {
        // Gebieszugriff
        if(district !== null && areas[district].area === tokens.area) {
          return <District id={district} />;
        }
        return <Area id={tokens.area} />;
      }
      if (typeOfArea === "district") {
        // Verkaufsgebiet
        return <District id={tokens.area}/>;
      }
          
    }
    return <Error />;
  }
  
  return <FindArea />;
}

export default Areas