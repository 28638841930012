import { getAuth } from "firebase/auth";
import { getDatabase, ref, push, set, serverTimestamp, runTransaction} from "firebase/database";
import removeEmptyValues from "../../../functions/objects/removeEmptyValues";

export const saver = async (target, object) => {
  const user = getAuth().currentUser;
  //const claims = await getIdTokenResult(user);

  const newRef = push(ref(getDatabase(), target));
  const cleanObject = removeEmptyValues(object);

  if(target === "contacts") {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    
    const statsRef = ref(getDatabase(), `stats/${year}/${month}/${user.uid}/${day}/c`);
    runTransaction(statsRef, (currentValue) => {
      if (currentValue === null) return 1;
      else return currentValue + 1;
    });
  }

  set(newRef, {...cleanObject, addedBy: user.uid, addedAt: serverTimestamp()})
  .then((obj) => {return obj.key;})
  .catch((err) => {console.log(err);});
};