import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../../root/redux/actions/database/loader";

import Loading from "../../modals/Loading";
import Error from "../../modals/Error";
import AddNew from "./AddNew";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";

const Products = () => {

  const { settings, products, productsLoaded, productsError} = useSelector(state => state.app);
  const role = useSelector(state => state.auth.tokens.role);
  const nof = settings.nof

  const dispatch = useDispatch();


  useEffect(() => {
    if(!productsLoaded) {
      dispatch(loader("products"));
    }
    // eslint-disable-next-line
  }, [productsLoaded]);

  if(!productsLoaded) return <Loading />;
  if(productsError !== null) return <Error />;

 
  return (
    <>
    <h3 className="py-4">Produkte</h3>
    <Accordion>
      {
        (products !== null) && Object.entries(products).map(([key, product], index) => 
        <Accordion.Item key={index} eventKey={key}>
          <Accordion.Header>
            <span className="Werk">Werk</span><span className="APP">APP</span> - {product.name} ({ (product.price * 1).toLocaleString("de-DE", nof) } € pro Monat / Lizenz)
          </Accordion.Header>
          <Accordion.Body>
            <p>
              {product?.description.includes("WerkAPP") ? (
                <>
                  {product.description.split("WerkAPP")[0]}
                  <span className="Werk">Werk</span>
                  <span className="APP">APP</span>
                  {product.description.split("WerkAPP")[1]}
                </>
              ) : (
                product?.description
              )}
            </p>
            <h5>Funktionen</h5>
            <Table bordered striped>
              <tbody>
                {
                  product.functions.map((f, i) => <tr key={i}>
                    <td><b>{ f.name }</b><br/>{ f.description }</td>
                  </tr>)
                
                }
              </tbody>
            </Table>
            <h5 className="mt-4">Inklusive pro Lizenz</h5>
            <Table bordered striped>
              <tbody>
                {
                  product?.includes.map((f, i) => {
                  if(f.qty === "0" || f.qty === 0) return"";
                  return (
                  <tr className="fw-semibold" key={i}>
                    <td>{ f.name }</td>
                    <td>{ ((f.step * 1) < 1) ? (f.qty * 1).toLocaleString("de-DE", nof) : f.qty } { f.format }</td>
                  </tr>); })
                
                }
              </tbody>
            </Table>
            {
              (product?.requiered && product.requiered.length > 0 ) && <>
            <h5 className="mt-4">Vorraussetzungen</h5>
            <Table bordered striped>
              <tbody>
                {
                  product.requiered.map((f, i) => 
                  <tr className="fw-semibold" key={i}>
                    <td>{ products[f].name }</td>
                  </tr>)
                
                }
              </tbody>
            </Table>
            </>
            }
          </Accordion.Body>
        </Accordion.Item>)
      }
      {
        (role === "cmo" || role === "ceo") && <AddNew products={products}/>
      }
    </Accordion>
    </>
  )
}

export default Products