import { getAuth } from "firebase/auth";
import { getDatabase, push, ref, serverTimestamp, update} from "firebase/database";
import detectChanges from "../../../functions/objects/detectChanges";
import removeEmptyValues from "../../../functions/objects/removeEmptyValues";

export const updater = async (target, object, id = null, before = {}) => {
  
  const user = getAuth().currentUser;
  
  const cleanObject = removeEmptyValues(object);
  const cleanBefore = removeEmptyValues(before);
  if(Object.keys(before).length !== 0) {
    const changes = detectChanges(cleanBefore, cleanObject, ["datumInt","dokumente", "projectId", "teilleistungen", "contactId", "architectId", "id", "addedAt", "addedBy"]);
    changes.at = serverTimestamp();
    changes.by = user.uid;
    changes.target = id;
    push(ref(getDatabase(), "history/"), changes);
  }
  const thisRef = ref(getDatabase(), target + "/" + id);
  update(thisRef, {...cleanObject});
};