import {useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { getDatabase, onValue, ref } from "firebase/database";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../../root/redux/actions/database/loader";
import Loading from "../../modals/Loading";
import Error from "../../modals/Error";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
);

const Dashboard = () => {

  const today = new Date();

  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth() + 1);
  const [showYear, setShowYear] = useState(today.getFullYear());
  const [showMonth, setShowMonth] = useState(today.getMonth() + 1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [unlisten, setListener] = useState(null);
  const { team, teamLoaded, teamError, settings } = useSelector(state => state.app);
  const { displayName } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const monthNames = [
    null,
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  const getYears = () => {
    let list = [];
    for (let i = 2023; i <= today.getFullYear(); i++) {
      list.push(i);
    }
    return list;
  }

  const getMonths = () => {
    let list = [];
    const max = (parseInt(year) === today.getFullYear() ? today.getMonth() + 1 : 12);
    for (let i = 1; i <= max; i++) {
      list.push([i, monthNames[i]]);
    }
    return list;
  }

  const [monthList, setMonthList] = useState(getMonths());

  useEffect(() => {
    if(parseInt(year) === today.getFullYear()) {
      setMonth(today.getMonth() + 1);
    }
    setMonthList(getMonths());
    // eslint-disable-next-line
  }, [year]);

  const handleRefresh = (force = false) => {
    if(parseInt(month) === parseInt(showMonth) && parseInt(year) === parseInt(showYear) && !force) return;
    if (unlisten !== null) {
      unlisten();
    }
    setLoading(true);
    const db = getDatabase();
    const listener = onValue(ref(db, "stats/" + year + "/" + month), (result) => {
      setError(false);
      setShowMonth(month);
      setShowYear(year);
      if (result.exists()) {
        setData(getChartData(result.exportVal()));
        setTimeout(() => setLoading(false), 500);
      } else {
        setData(getChartData({}));
        setTimeout(() => setLoading(false), 500);
      }
    }, (err) => {
      console.log(err);
      setError(true);
      setLoading(false);
      setData({});
    });
    setListener(() => listener);
  };
  
  const getChartData = (data) => {

    const days = new Date(year, month - 1, 0).getDate();

    const labels = Array.from({ length: days}, (_, i) => 1 + i);
    
    const contacts = [];
    const contactsTotal = [];
    
    const clients = [];
    const clientsTotal = [];
    
    const mrr = [];
    const mrrTotal = [];
    
    const contactsTop = [];
    const clientsTop = [];
    const mrrTop = [];
    
    const option = {
      pointRadius: 0,
      backgroundColor: "",
      borderColor: "",
    };
    const users = Object.keys(data);
    for (let i = 0; i < users.length; i++) {
      const uid = users[i];
      let newK = [];
      let totalK = [];
      let sumK = 0;
      let newA = [];
      let totalA = [];
      let sumA = 0;
      let newMRR = [];
      let totalMRR = [];
      let sumMRR = 0;
      for (let d = 1; d <= days; d++) {
        if(data[uid].hasOwnProperty(d)) {
          newK.push(data[uid][d]?.c || 0);
          sumK = sumK + (data[uid][d]?.c || 0);
          totalK.push(sumK);
          newA.push(data[uid][d]?.a || 0);
          sumA = sumA + (data[uid][d]?.a || 0);
          totalA.push(sumA);
          newMRR.push(data[uid][d]?.mrr || 0);
          sumMRR = sumMRR + (data[uid][d]?.mrr || 0);
          totalMRR.push(sumMRR);
        } else {
          newK.push(0);
          totalK.push(sumK);
          newA.push(0);
          totalA.push(sumA);
          newMRR.push(0);
          totalMRR.push(sumMRR);
        }
      }
      contacts.push({data: newK, label: team[uid].displayName, option});
      contactsTotal.push({data: totalK, label: team[uid].displayName, option});
      clients.push({data: newA, label: team[uid].displayName, option});
      clientsTotal.push({data: totalA, label: team[uid].displayName, option});
      mrr.push({data: newMRR, label: team[uid].displayName, option});
      mrrTotal.push({data: totalMRR, label: team[uid].displayName, option});

      contactsTop.push({count: sumK, displayName: team[uid].displayName, color: ""});
      clientsTop.push({count: sumA, displayName: team[uid].displayName, color: ""});
      mrrTop.push({count: sumMRR, displayName: team[uid].displayName, color: ""});
    }

  contactsTop.sort((a,b) => b.count - a.count).slice(Math.max(contactsTop.length - 5, 0));
  clientsTop.sort((a,b) => b.count - a.count).slice(Math.max(clientsTop.length - 5, 0));
  mrrTop.sort((a,b) => b.count - a.count).slice(Math.max(mrrTop.length - 5, 0));

  const topContacts = [...contactsTop].sort((a,b) => b.count - a.count).slice(Math.max(contactsTop.length - 5, 0)).map((item) => item.displayName);
  const topClients = [...clientsTop].sort((a,b) => b.count - a.count).slice(Math.max(clientsTop.length - 5, 0)).map((item) => item.displayName);
  const topMRR = [...mrrTop].sort((a,b) => b.count - a.count).slice(Math.max(mrrTop.length - 5, 0)).map((item) => item.displayName);

  const filteredContacts = contacts.filter((item) => (topContacts.includes(item.label) || item.label === displayName));
  const filteredContactsTotal = contactsTotal.filter((item) => (topContacts.includes(item.label) || item.label === displayName));
  const filteredClients = clients.filter((item) => (topClients.includes(item.label) || item.label === displayName));
  const filteredClientsTotal = clientsTotal.filter((item) => (topClients.includes(item.label) || item.label === displayName));
  const filteredMRR = mrr.filter((item) => (topMRR.includes(item.label) || item.label === displayName));
  const filteredMRRTotal = mrrTotal.filter((item) => (topMRR.includes(item.label) || item.label === displayName));

  let i = 0;
  const colors = ["#0076ba", "#1db100", "#1d96d1", "#00cc39", "#53bfed", "#7de47d"];

  for (const item of Object.keys(filteredClients)) {
    filteredClients[item].borderColor = colors[i];
    filteredClients[item].backgroundColor = colors[i] + "15";
    filteredClientsTotal[item].borderColor = colors[i];
    filteredClientsTotal[item].backgroundColor = colors[i] + "15";
    clientsTop[item].color = colors[i];
    i = (i + 1) % colors.length;
  }
  i = 0;
  for (const item of Object.keys(filteredContacts)) {
    filteredContacts[item].borderColor = colors[i];
    filteredContacts[item].backgroundColor = colors[i] + "15";
    filteredContactsTotal[item].borderColor = colors[i];
    filteredContactsTotal[item].backgroundColor = colors[i] + "15";
    contactsTop[item].color = colors[i];
    i = (i + 1) % colors.length;
  }
  i = 0;
  for (const item of Object.keys(filteredMRR)) {
    filteredMRR[item].borderColor = colors[i];
    filteredMRR[item].backgroundColor = colors[i] + "15";
    filteredMRRTotal[item].borderColor = colors[i];
    filteredMRRTotal[item].backgroundColor = colors[i] + "15";
    mrrTop[item].color = colors[i];
    i = (i + 1) % colors.length;
  }


    return {
      contactsTop,
      clientsTop,
      mrrTop,
      clients: {
        labels,
        datasets: filteredClients,
      },
      clientsTotal: {
        labels,
        datasets: filteredClientsTotal,
      },
      contacts: {
        labels,
        datasets: filteredContacts,
      },
      contactsTotal: {
        labels,
        datasets: filteredContactsTotal,
      },
      mrr: {
        labels,
        datasets: filteredMRR,
      },
      mrrTotal: {
        labels,
        datasets: filteredMRRTotal,
      },
    };
  };

  const [chartData, setData] = useState(getChartData({}));

  const options = {
    responsive: true,
    tension: 0.25,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    fill: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    tooltips: {
      mode: 'index', // Zeigt Informationen für alle Linien an der x-Achse an
      intersect: true,
    },
    elements:{
      point:{
          borderWidth: 0,
          radius: 0,
      }
    },
    scales: {
      x: {
        ticks: {
          display: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    }
  };

  useEffect(() => {
    return(() => {
      if (unlisten !== null) { unlisten(); }
    })
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(!teamLoaded) {
      dispatch(loader("team"));
    }
    if(teamLoaded) {
      handleRefresh(true);
    }
    // eslint-disable-next-line
  }, [teamLoaded]);

  if(teamError !== null || error) {
    <Error />;
  }
  else if(!teamLoaded || loading) {
    return <Loading />;
  }

  return (
    <Card>
      <Card.Header>
        <Row>
          <Col xs={8} md={5}>
            <InputGroup>
              <Form.Select value={month} onChange={e => setMonth(e.target.value)}>
                {
                  monthList.map(([key, name], index) => <option key={index} value={key}>{name}</option>)
                }
              </Form.Select>
              <Form.Select value={year} onChange={e => setYear(e.target.value)}>
                {
                  getYears().map((value, index) => <option key={index} value={value}>{value}</option>)
                }
              </Form.Select>
              <Button
                variant={(parseInt(year) === parseInt(showYear) && parseInt(month) === parseInt(showMonth)) ? "outline-primary" : "primary"}
                onClick={handleRefresh}
                disabled={(parseInt(year) === parseInt(showYear) && parseInt(month) === parseInt(showMonth))}
                >Aktualisieren</Button>
            </InputGroup>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <h3 className="mt-3 text-center">Neue Kontakte im { monthNames[showMonth] } { showYear }</h3>
        <Row className="mb-5">
          <Col xs={12} md={4}>
            <h4 className="mt-4 mb-2 text-center">Tageswerte</h4>
            {
              (chartData?.contacts?.datasets.length === 0) ? <div className="emptyDiagramm">Keine Daten vorhanden</div> : <Line data={chartData.contacts} options={options} />
            }
          </Col>
          <Col xs={12} md={4}>
            <h4 className="mt-4 mb-2 text-center">Kummuliert</h4>
            {
              (chartData?.contactsTotal?.datasets.length === 0) ? <div className="emptyDiagramm">Keine Daten vorhanden</div> : <Line data={chartData.contactsTotal} options={options} />
            }
          </Col>
          <Col xs={12} md={4}>
            <h4 className="mt-4 mb-2 text-center">Top 5</h4>
            {
              ( chartData?.contactsTop?.length === 0 ) ? <div className="emptyDiagramm">Keine Daten vorhanden</div> : chartData.contactsTop.map((item, index) => <Row key={index} className="mb-1 align-items-center fw-semibold">
                <Col xs={1} style={{backgroundColor: item.color, aspectRatio:"1/.5"}} />
                <Col xs={6}>{ item.displayName }</Col>
                <Col align="end">+ { item.count.toFixed(0) }</Col>
              </Row>)
            }
          </Col>
        </Row>
        
        <h3 className="mt-3 text-center">Abschlüsse im { monthNames[showMonth] } { showYear }</h3>
        <Row className="mb-5">
          <Col xs={12} md={4}>
            <h4 className="mt-4 mb-2 text-center">Tageswerte</h4>
            {
              (chartData?.clients?.datasets.length === 0) ? <div className="emptyDiagramm">Keine Daten vorhanden</div> : <Line data={chartData.clients} options={options} />
            }
          </Col>
          <Col xs={12} md={4}>
            <h4 className="mt-4 mb-2 text-center">Kummuliert</h4>
            {
              (chartData?.clientsTotal?.datasets.length === 0) ? <div className="emptyDiagramm">Keine Daten vorhanden</div> : <Line data={chartData.clientsTotal} options={options} />
            }
          </Col>
          <Col xs={12} md={4}>
            <h4 className="mt-4 mb-2 text-center">Top 5</h4>
            {
              ( chartData?.clientsTop?.length === 0 ) ? <div className="emptyDiagramm">Keine Daten vorhanden</div> : chartData.clientsTop.map((item, index) => <Row key={index} className="mb-1 align-items-center fw-semibold">
                <Col xs={1} style={{backgroundColor: item.color, aspectRatio:"1/.5"}} />
                <Col xs={6}>{ item.displayName }</Col>
                <Col align="end">+ { item.count.toFixed(0) }</Col>
              </Row>)
            }
          </Col>
        </Row>

        <h3 className="mt-3 text-center">Umsatzzuwachs im { monthNames[showMonth] } { showYear }</h3>
        <Row className="mb-5">
          <Col xs={12} md={4}>
            <h4 className="mt-4 mb-2 text-center">Tageswerte</h4>
            {
              (chartData?.mrr?.datasets.length === 0) ? <div className="emptyDiagramm">Keine Daten vorhanden</div> : <Line data={chartData.mrr} options={options} />
            }
          </Col>
          <Col xs={12} md={4}>
            <h4 className="mt-4 mb-2 text-center">Kummuliert</h4>
            {
              (chartData?.mrrTotal?.datasets.length === 0) ? <div className="emptyDiagramm">Keine Daten vorhanden</div> : <Line data={chartData.mrrTotal} options={options} />
            }
          </Col>
          <Col xs={12} md={4}>
            <h4 className="mt-4 mb-2 text-center">Top 5</h4>
            {
              ( chartData?.mrrTop?.length === 0 ) ? <div className="emptyDiagramm">Keine Daten vorhanden</div> : chartData.mrrTop.map((item, index) => <Row key={index} className="mb-1 align-items-center fw-semibold">
                <Col xs={1} style={{backgroundColor: item.color, aspectRatio:"1/.5"}} />
                <Col xs={6}>{ item.displayName }</Col>
                <Col align="end">+ { item.count.toLocaleString(settings.nof) }€</Col>
              </Row>)
            }
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default Dashboard