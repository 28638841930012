import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../root/redux/actions/database/loader";
import {  BsPinMapFill, BsSortAlphaDown, BsSortAlphaUp, BsSortNumericUp, BsSortNumericDown } from "react-icons/bs";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Tooltip from "react-bootstrap/Tooltip";
import Error from "../../modals/Error";
import Loading from "../../modals/Loading";

const FilterAndSort = ({ q = null, status = null, modalAdd, modalDetail}) => {
  const dispatch = useDispatch();
  const { contacts, contactsLoaded, contactsError, team, teamLoaded, teamError, areas, areasLoaded, areasError, zip, zipLoaded, zipError} = useSelector(state => state.app);
  const { role } = useSelector(state => state.auth.tokens);
  const [sortedContacts, setSortedContacts] = useState([]);
  const [sortProperty, setSortProperty] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const pageSize = 25;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSort = (property) => {
    if (property === sortProperty) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortProperty(property);
      setSortOrder("asc");
    }
  };
  
  useEffect(() => {
    if (!contactsLoaded) {
      dispatch(loader("contacts"));
    }
    if (!teamLoaded) {
      dispatch(loader("team"));
    }
    if (!areasLoaded) {
      dispatch(loader("areas"));
    }
    if (!zipLoaded) {
      dispatch(loader("zip"));
    }
  }, [contactsLoaded, teamLoaded, areasLoaded, zipLoaded, dispatch]);

  useEffect(() => {
    if(contacts !== null) {
    let sorted = Object.entries(contacts).sort(([, a], [, b]) => {
      if (a && b) {
        const xA = a.name || "";
        const xB = b.name || "";
        return xA.localeCompare(xB);
      }
      return 0;
    });

    if (sortProperty) {
      sorted.sort(([, a], [, b]) => {
        const valueA = a && a[sortProperty] ? a[sortProperty].toString().toLowerCase() : "";
        const valueB = b && b[sortProperty] ? b[sortProperty].toString().toLowerCase() : "";

        if (sortOrder === "asc") {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      });
    }
    if(status) {
      sorted = sorted.filter(([_, contact]) => {
        if(status === "Alle") {
          return true;
        }
        else if (contact.hasOwnProperty("status") && contact.status === status) {
          return true;
        }
        return false;
      });
    }
    if (q) {
      sorted = sorted.filter(([, contact]) => {
        const searchQuery = q
          .toLowerCase()
          .replace(/[^a-z0-9]/gi, "")
          .replace(/strasse|straße|str./g, "str");
        
        const name = contact.name || "";
        const address = contact.anschrift || "";
        const zipCodeAndCity = (contact.PLZ || "") + " " + (contact.ort || "");

        return (
          name.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          address.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          zipCodeAndCity.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery)
        );
      });
    }

    setSortedContacts(sorted);
  }
  }, [contacts, sortProperty, sortOrder, q, status]);

  if (contactsError !== null || teamError !== null || areasError !== null || zipError !== null) {
    return <Error />;
  }

  if (!contactsLoaded || !teamLoaded || !areasLoaded || !zipLoaded) {
    return <Loading />;
  }

  const createKey = (mitarbeiter = 0, lastBill = 0, status = "Kontakt") => {

    const umsatz = {A: 1774.50, B: 344.19, C: 153.99, D: 94.68, E: 40.56};

    let str = "";
    const staff = parseInt(mitarbeiter);
    
    if (staff >= 50) str += "A";
    else if (staff >= 20) str += "B";
    else if (staff >= 10) str += "C";
    else if (staff >= 5) str += "D";
    else if (staff > 0) str += "E";
    else str += "U";
    
    const percent = (lastBill / umsatz[str])*100;
    
    if (str === "U") str += "0.";
    else if (lastBill === 0) str += "0.";
    else {
      if (percent >= 150) str += "1.";
      else if (percent >= 120) str += "2.";
      else if (percent >= 110) str += "3.";
      else if (percent >= 90) str += "4.";
      else if (percent >= 80) str += "5.";
      else if (percent >= 50) str += "6.";
      else str += "7.";
    }
    
    if (status === "Kontakt") str += "3";
    else if (status === "Aktiv") str += "1";
    else if (status === "Inaktiv") str += "5";
    else if (status === "Gesperrt") str += "7";
    else if (status === "Verdächtig") str += "9";
    else str += "0";

    return str;
  }

  const teamName = id => {
    if (team === null || team === undefined || Object.keys(team).length === 0) return <span style={{opacity:.3}}>leer</span>;
    else if (Object.keys(team).includes(id)) {
      return team[id].displayName;
    }
    return <span style={{opacity:.3}}>leer</span>;
  }

  const totalContacts = sortedContacts.length;
  const totalPages = Math.ceil(totalContacts / pageSize);
  const contactsToShow = sortedContacts.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const renderTooltip = (title) => (
    <Tooltip id="button-tooltip" placement="top" delay={{ show: 250, hide: 400 }}>
      {title}
    </Tooltip>
  );
  if (contacts === null || Object.keys(contacts).length === 0) {
    return (
      <Alert variant="primary">
        Sie haben noch keine Kontakte, legen Sie jetzt eine an.
        <br />
        <br />
        <Button variant="primary" onClick={modalAdd}>
          Neuer Kontakt
        </Button>
      </Alert>
    );
  }
  else if (contactsToShow.length === 0) {
    return (
      <Alert variant="primary">
        Es gibt keine Übereinstimmungen.
      </Alert>
    );
  }
  const renderMapsString = p => {
    const str = (p?.anschrift ? p.anschrift + ', ' : '');
    const plz = (p?.PLZ ? p.PLZ + ' ' : '');
    const ort = (p?.ort ? p.ort : '');
    return (str + '' + plz + ort).replace(' ','+');
  }
  const formatUnixString = (unixString = null) => {
    if(unixString === null) return "";
    var dateTime = new Date(unixString);
    var day = dateTime.getDate().toString().padStart(2, "0");
    var month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    var year = dateTime.getFullYear();
    var formattedDateTime = day + "." + month + "." + year;
    return formattedDateTime;
  }
  
  const getAreaUser = (zipCode, show = "district") => {
    if(zipCode === null || zipCode === "" || zipCode === undefined) return teamName(null);
    const zC = zipCode.substring(0,3);
    for (const item in zip) {
      if(zip[item].zip === zC) {
          if (show==="region" && zip[item]?.region) return teamName(areas[zip[item]?.region]?.user);
          else if (show==="area" && zip[item]?.area) return teamName(areas[zip[item]?.area]?.user);
          else if (show==="district" && zip[item]?.district) return teamName(areas[zip[item]?.district]?.user);
      };
    }
    return teamName(null);
  }
  const getAreaName = (zipCode, show = "district") => {
    if(zipCode === null || zipCode === "" || zipCode === undefined) return "";
    const zC = zipCode.substring(0,3);
    for (const item in zip) {
      if(zip[item].zip === zC) {
          if (show==="region" && zip[item]?.region) return areas[zip[item]?.region]?.name;
          else if (show==="area" && zip[item]?.area) return areas[zip[item]?.area]?.name;
          else if (show==="district" && zip[item]?.district) return areas[zip[item]?.district]?.name;
      };
    }
    return "";
  }

  if (role === "hv" || role === "adm") {
    return (
      <>
        <Table striped hover>
          <thead>
            <tr>
              <th>
                  KPI
              </th>
              <th onClick={() => handleSort("name")} style={{cursor:"pointer"}}>
                  Name {sortProperty === "name" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
              </th>
              <th onClick={() => handleSort("anschrift")} style={{cursor:"pointer"}}>
                Anschrift {sortProperty === "anschrift" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
              </th>
              <th onClick={() => handleSort("PLZ")} style={{cursor:"pointer"}}>
                PLZ {sortProperty === "PLZ" && (sortOrder === "asc" ? <BsSortNumericDown /> : <BsSortNumericUp />)}
              </th>
              <th onClick={() => handleSort("ort")} style={{cursor:"pointer"}}>
                Ort {sortProperty === "ort" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
              </th>
              <th onClick={() => handleSort("district")} style={{cursor:"pointer"}}>
                Bezirk {sortProperty === "district" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {contactsToShow.map(([key, contact], index) => (
              <tr key={index}>
                <td valign="middle" style={contact?.online ? {color:"var(--accent1"}:{}}>{ createKey(contact?.mitarbeiter, contact?.lastBill, contact?.status) }</td>
                <td valign="middle">{ contact?.name || "" }</td>
                <td valign="middle">{ contact?.anschrift || ""}</td>
                <td valign="middle">{ contact?.PLZ || "" }</td>
                <td valign="middle">{ contact?.ort || "" }</td>
                <td valign="middle">{ contact?.online ? "(Online Vertrieb)" : (contact?.assignedTo && contact?.assignedTo !== contact?.district) ? "(" + teamName(contact?.assignedTo || null) + ")" : getAreaUser(contact?.PLZ, "district") }
                </td>
                <td valign="middle" align="end">
                  <ButtonGroup>
                    <OverlayTrigger overlay={renderTooltip("In Google Maps anzeigen")}>
                      <Button as={Link} to={"https://www.google.com/maps/place/" + renderMapsString(contact)} rel="nofollow" target="_blank" variant="outline-primary" size="sm">
                        <BsPinMapFill />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={renderTooltip("Details öffnen")}>
                      <Button onClick={() => modalDetail({ id: key, el: contacts[key] })} variant="outline-primary" size="sm">
                        Details
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination className="text-center">
          {
            (currentPage > 1) && <>
              <Pagination.First onClick={() => handlePageChange(1)}/>
              <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}/>
            </>
          }
          {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Pagination.Item>
          ))}
          {
            (currentPage < totalPages) && <>
              <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}/>
              <Pagination.Last onClick={() => handlePageChange(totalPages)}/>
            </>
          }
          </Pagination>
        </div>
      </>
    );
  }
  else if (role === "gvl") {
    return (
      <>
        <Table striped hover>
          <thead>
            <tr>
              <th>
                  KPI
              </th>
              <th onClick={() => handleSort("name")} style={{cursor:"pointer"}}>
                Name {sortProperty === "name" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
              </th>
              <th onClick={() => handleSort("PLZ")} style={{cursor:"pointer"}}>
                PLZ {sortProperty === "PLZ" && (sortOrder === "asc" ? <BsSortNumericDown /> : <BsSortNumericUp />)}
              </th>
              <th onClick={() => handleSort("district")} style={{cursor:"pointer"}}>
                Bezirk {sortProperty === "district" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
              </th>
              <th onClick={() => handleSort("assignedTo")} style={{cursor:"pointer"}}>
                Zugeordnet {sortProperty === "assignedTo" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
              </th>
              <th onClick={() => handleSort("addedAt")} style={{cursor:"pointer"}}>
                Hinzugefügt {sortProperty === "addedAt" && (sortOrder === "asc" ?<BsSortNumericDown /> : <BsSortNumericUp />)}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {contactsToShow.map(([key, contact], index) => (
              <tr key={index}>
                <td valign="middle" style={contact?.online ? {color:"var(--accent1"}:{}}>{ createKey(contact?.mitarbeiter, contact?.lastBill, contact?.status) }</td>
                <td valign="middle">{ contact?.name || "" }</td>
                <td valign="middle">{ contact?.PLZ || "" }</td>
                <td valign="middle">{ getAreaUser(contact?.PLZ, "district") }</td>
                <td valign="middle">{ contact?.online ? "Online Vertrieb" : contact?.assignedTo ? teamName(contact?.assignedTo || null) : "ADM / HV"}</td>
                <td valign="middle">{ formatUnixString(contact?.addedAt || null) }</td>
                <td valign="middle" align="end">
                  <ButtonGroup>
                    <OverlayTrigger overlay={renderTooltip("In Google Maps anzeigen")}>
                      <Button as={Link} size="sm" to={"https://www.google.com/maps/place/" + renderMapsString(contact)} rel="nofollow" target="_blank" variant="outline-primary">
                        <BsPinMapFill />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={renderTooltip("Details öffnen")}>
                      <Button onClick={() => modalDetail({ id: key, el: contacts[key] })} variant="outline-primary" size="sm">
                        Details
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination className="text-center">
          {
            (currentPage > 1) && <>
              <Pagination.First onClick={() => handlePageChange(1)}/>
              <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}/>
            </>
          }
          {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Pagination.Item>
          ))}
          {
            (currentPage < totalPages) && <>
              <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}/>
              <Pagination.Last onClick={() => handlePageChange(totalPages)}/>
            </>
          }
          </Pagination>
        </div>
      </>
    );
  }
  else {
    return (
      <>
        <Table striped hover>
          <thead>
            <tr>
              <th>
                  KPI
              </th>
              <th onClick={() => handleSort("name")} style={{cursor:"pointer"}}>
                  Name {sortProperty === "name" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
              </th>
              <th onClick={() => handleSort("PLZ")} style={{cursor:"pointer"}}>
                PLZ {sortProperty === "PLZ" && (sortOrder === "asc" ? <BsSortNumericDown /> : <BsSortNumericUp />)}
              </th>
              <th>
                Region
              </th>
              <th>
                Gebiet
              </th>
              <th onClick={() => handleSort("district")} style={{cursor:"pointer"}}>
                Bezirk {sortProperty === "district" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
              </th>
              <th onClick={() => handleSort("assignedTo")} style={{cursor:"pointer"}}>
                Zugeordnet {sortProperty === "assignedTo" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
              </th>
              <th onClick={() => handleSort("addedAt")} style={{cursor:"pointer"}}>
                Hinzugefügt {sortProperty === "addedAt" && (sortOrder === "asc" ?<BsSortNumericDown /> : <BsSortNumericUp />)}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {contactsToShow.map(([key, contact], index) => (
              <tr key={index}>
                <td valign="middle" style={contact?.online ? {color:"var(--accent1"}:{}}>{ createKey(contact?.mitarbeiter, contact?.lastBill, contact?.status) }</td>
                <td valign="middle">{ contact?.name || "" }</td>
                <OverlayTrigger overlay={renderTooltip( contact.ort )} placement="bottom"><td valign="middle">
                  { contact?.PLZ }
                </td></OverlayTrigger>
                <OverlayTrigger overlay={renderTooltip( getAreaUser(contact?.PLZ, "region") )} placement="bottom"><td valign="middle">
                  { getAreaName(contact?.PLZ, "region") }</td></OverlayTrigger>
                <OverlayTrigger overlay={renderTooltip( getAreaUser(contact?.PLZ, "area") )} placement="bottom"><td valign="middle">
                  { getAreaName(contact?.PLZ, "area") }</td></OverlayTrigger>
                <OverlayTrigger overlay={renderTooltip( getAreaUser(contact?.PLZ, "district") )} placement="bottom"><td valign="middle">
                  { getAreaName(contact?.PLZ, "district") }</td></OverlayTrigger>
                <td valign="middle">{ contact?.online ? "Online Vertrieb" : contact?.assignedTo ? teamName(contact?.assignedTo || null) : "ADM / HV"}</td>
                <td valign="middle">{ formatUnixString(contact?.addedAt || null) }</td>
                <td valign="middle" align="end">
                  <OverlayTrigger overlay={renderTooltip("Details öffnen")}>
                    <Button onClick={() => modalDetail({ id: key, el: contacts[key] })} variant="outline-primary" size="sm">
                      Details
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination className="text-center">
          {
            (currentPage > 1) && <>
              <Pagination.First onClick={() => handlePageChange(1)}/>
              <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}/>
            </>
          }
          {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Pagination.Item>
          ))}
          {
            (currentPage < totalPages) && <>
              <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}/>
              <Pagination.Last onClick={() => handlePageChange(totalPages)}/>
            </>
          }
          </Pagination>
        </div>
      </>
    );
  }

 
};

export default FilterAndSort;
