import { equalTo, get, getDatabase, orderByChild, push, set, query, ref, update, serverTimestamp } from "firebase/database";
import { useEffect, useState } from "react";
import Loading from "../../modals/Loading";
import Error from "../../modals/Error";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { defaultData } from "./defaultData";
import removeEmptyValues from "../../../root/functions/objects/removeEmptyValues";
import { useSelector } from "react-redux";
import detectChanges from "../../../root/functions/objects/detectChanges";


const PersonsList = (props) => {

  const { contactId } = props;
  const { uid } = useSelector(state => state.auth);
  const [persons, setPersons] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [hasError, setError] = useState(false);
  const [addNew, setAddNew] = useState(false);

  const [editObj, setEditObj] = useState({});
  const [editId, setEditId] = useState(null);

  const handleEdit = (id, obj) => {
    setEditObj(obj);
    setEditId(id);
    setAddNew(true);
  }
  const handleAddNew = () => {
    setEditObj({});
    setEditId(null);
    setAddNew(true);
  }

  const handleDelete = (id, name) => {
    if(window.confirm(name + " unwiederruflich löschen?")) {
      set(ref(getDatabase(), "persons/"+id), null)
      .then(() => {
        push(ref(getDatabase(), "history"), {
          // Add History delete
          target: contactId,
          by: uid,
          at: serverTimestamp(),
          changes: [
            { 
              key: "Ansprechpartner",
              before: persons[id].vorname + " " + persons[id].name,
              after: null
            }
          ]
        });
        setPersons(prev => {
          return removeEmptyValues({...prev, [id]: null});
        });
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }

  const loader = () => {
    const db = getDatabase();
    const personRef = query(ref(db, "persons"),orderByChild("contactId"),equalTo(contactId));
    get(personRef)
    .then((response) => {
      setLoaded(true);
      setPersons(response.exportVal());
    })
    .catch((err) => {
      console.log(err);
      setLoaded(true);
      setError(true);
    });
  }

  useEffect(() => {
    if(contactId !== undefined && contactId !== null) {
      loader();
    }
    // eslint-disable-next-line
  }, []);

  if(!isLoaded) return <Loading />;

  if(hasError) return <Error />;

  const NewItem = ({obj = {}, objId = null}) => {

    const [newPerson, setNewPerson] = useState({...defaultData, ...obj, contactId: contactId});
    const [isDirty, setDirty] = useState(false);
    const [validated, setValidated] = useState(false);

    const handlePersonChange = e => {
      setDirty(true);
      setNewPerson(prev => ({...prev, [e.target.name]: e.target.value}));
    }

    const handleReset = () => {
      setAddNew(false);
      setDirty(false);
      setNewPerson({...defaultData, contactId: contactId});
    }

    const handleSave = e => {
      e.preventDefault();
      e.stopPropagation();
      const form = e.currentTarget;

      if (form.checkValidity() === false) {
        setValidated(true);
        return;
      }
      const displayName = ((newPerson.vorname === "") ? "" : newPerson.vorname + " ")+(newPerson.name === "" ? "" : newPerson.name);
      const data = removeEmptyValues({...newPerson, contactId: contactId, displayName: displayName.trim()});
      const dataBefore = removeEmptyValues({...obj, contactId: contactId});
      const changes = detectChanges(dataBefore, data);
      changes.by = uid;
      changes.at = serverTimestamp();
      changes.target = contactId;
      setValidated(false);
      if(objId !== null) {
        update(ref(getDatabase(),"persons/"+objId), data)
        .then(() => {
          push(ref(getDatabase(), "history/"), changes);
          setPersons(prev => ({...prev, [objId]: data}));
          setAddNew(false);
          setDirty(false);
          setNewPerson({...defaultData, contactId: contactId});
        })
        .catch((error) => {
          console.log(error);
        });
      } else {
        push(ref(getDatabase(), "persons"), data)
        .then((result) => {
          push(ref(getDatabase(), "history"), {
            // Add History delete
            target: contactId,
            by: uid,
            at: serverTimestamp(),
            changes: [
              { 
                key: "Ansprechpartner",
                after: displayName,
                before: null
              }
            ]
          });
          setPersons(prev => ({...prev, [result.key]: data}));
          setAddNew(false);
          setDirty(false);
          setNewPerson({...defaultData, contactId: contactId});
        })
        .catch((error) => {
          console.log(error);
        });
      }
    }

    return (
      <Form onSubmit={handleSave} noValidate validated={validated}>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-2">
              <Form.Text>Anrede</Form.Text>
              <Form.Select value={newPerson.anrede} name="anrede" onChange={handlePersonChange} >
                <option value="">Bitte wählen</option>
                <option value="Frau">Frau</option>
                <option value="Herr">Herr</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Text>Vorname</Form.Text>
              <Form.Control value={newPerson.vorname} name="vorname" onChange={handlePersonChange} />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-2">
              <Form.Text>Name</Form.Text>
              <Form.Control value={newPerson.name} name="name" onChange={handlePersonChange} required/>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Text>E-Mail</Form.Text>
              <Form.Control type="email" value={newPerson.email} name="email" onChange={handlePersonChange} />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-2">
              <Form.Text>Mobil</Form.Text>
              <Form.Control type="phone" value={newPerson.mobil} name="mobil" onChange={handlePersonChange} />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Text>Telefon</Form.Text>
              <Form.Control type="phone" value={newPerson.telefon} name="telefon" onChange={handlePersonChange} />
            </Form.Group>
          </Col>
          <Col xs={12} md={true}>
            <Form.Group>
              <Form.Text>Notizen</Form.Text>
              <Form.Control as="textarea" rows="2" value={newPerson.notizen} name="notizen" onChange={handlePersonChange}  />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col align="end">
            <ButtonGroup>
              <Button variant="outline-primary" onClick={handleReset}>Verwerfen</Button>
              <Button variant="primary" type="submit" disabled={!isDirty}>Speichern</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Form>
    )
  }

  const ListItems = () => {

    const callable = (phone) => {
      if (phone.startsWith("0")) {
        return "+49" + phone.substr(1);
      }
      return phone;
    }
    const readable = (phone) => {
      const cleaned = callable(phone).replace(/\D\+/g, "");
      let formated = "";
      for (let i = 0; i < cleaned.length; i++) {
        if (i > 0 && i % 3 === 0) {
          formated += " ";
        }
        formated += cleaned[i];
      }
      return formated;
    }
    
    return (
    <Accordion>
      {
        (persons !== null && Object.keys(persons).length > 0) && Object.entries(persons).map(([key, person], index) => <Accordion.Item
        key={key}
        eventKey={key}
        >
          <Accordion.Header>{ (person?.anrede ? person?.anrede + " " : "") }{person?.name}{(person?.vorname) ? ", " + person.vorname : ""}</Accordion.Header>
          <Accordion.Body>
            { ( person?.mobil && <p className="mb-2"><span className="fw-semibold">Mobil:</span> <a href={"tel:"+callable(person.mobil)} rel="noreferrer" target="_blank">{readable(person.mobil)}</a></p>) }
            { ( person?.telefon && <p className="mb-2"><span className="fw-semibold">Telefon:</span> <a href={"tel:"+callable(person.telefon)} rel="noreferrer" target="_blank">{readable(person.telefon)}</a></p>) }
            { ( person?.email && <p className="mb-2"><span className="fw-semibold">E-Mail:</span> <a href={"mailto:"+person.email} rel="noreferrer" target="_blank">{person.email}</a></p>) }
            { ( person?.notizen && <p className="mb-2"><span className="fw-semibold">Notizen:</span> { person.notizen }</p>) }
            <ButtonGroup>
              <Button variant="outline-danger" size="sm" onClick={() => handleDelete(key, ((person?.anrede ? person?.anrede + " " : "") + person?.name + ((person?.vorname) ? ", " + person.vorname : "")))}>Löschen</Button>
              <Button variant="outline-primary" size="sm" onClick={() => handleEdit(key, person)}>Bearbeiten</Button>
            </ButtonGroup>
          </Accordion.Body>
        </Accordion.Item>)
      }
    </Accordion>
    );
  }


  return (<>
    <Row className="mb-2">
      <Col>
        <h6 className="fw-semibold">Ansprechpartner</h6>
      </Col>
      <Col align="end">
        {
          !addNew && <Button size="sm" variant="outline-primary" onClick={handleAddNew}>Hinzufügen</Button>
        }
      </Col>
    </Row>
      <div style={{height:"300px",overflowY:"scroll", overflowX: "hidden"}}>
        {
          (addNew) ? <NewItem obj={editObj} objId={editId} /> : <ListItems />
        }
      </div>
  </>);
}

export default PersonsList