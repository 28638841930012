export const zipCodes = [{zip:"010",km:74.02,people:80287,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"011",km:121.23,people:182357,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"012",km:51.24,people:157358,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"013",km:69.85,people:88723,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"014",km:269.65,people:90009,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"015",km:578.88,people:73129,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"016",km:750.76,people:125455,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"017",km:860.54,people:158677,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"018",km:967.77,people:120370,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"019",km:1354.01,people:151075,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"026",km:702.50,people:100196,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"027",km:676.58,people:129960,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"028",km:377.47,people:75459,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"029",km:1720.48,people:139746,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"030",km:532.79,people:124112,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"031",km:1351.33,people:101153,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"032",km:1229.53,people:79688,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"041",km:97.57,people:184397,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"042",km:111.09,people:190005,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"043",km:89.38,people:128833,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"044",km:308.59,people:91793,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"045",km:723.69,people:109019,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"046",km:1298.41,people:185953,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"047",km:888.74,people:106774,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"048",km:1609.83,people:143540,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"049",km:1079.15,people:60316,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"061",km:744.90,people:299853,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"062",km:966.44,people:146234,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"063",km:921.20,people:116555,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"064",km:1269.74,people:155734,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"065",km:1324.95,people:117147,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"066",km:1072.48,people:138250,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"067",km:1096.48,people:152255,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"068",km:1000.07,people:168487,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"069",km:979.56,people:41608,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"073",km:919.37,people:92675,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"074",km:434.82,people:49898,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"075",km:549.44,people:141288,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"076",km:509.83,people:56847,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"077",km:419.75,people:133980,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"078",km:203.74,people:17354,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"079",km:1000.21,people:97347,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"080",km:101.55,people:93246,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"081",km:296.93,people:62131,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"082",km:614.53,people:133307,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"083",km:609.84,people:127654,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"084",km:349.76,people:96265,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"085",km:375.01,people:81480,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"086",km:330.64,people:29336,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"091",km:176.56,people:225533,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"092",km:272.66,people:86556,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"093",km:611.52,people:144292,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"094",km:729.81,people:126039,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"095",km:611.82,people:114248,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"096",km:942.61,people:106453,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"101",km:10.80,people:79724,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"102",km:10.36,people:114821,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"103",km:26.23,people:153334,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"104",km:10.43,people:139757,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"105",km:12.66,people:109113,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"106",km:3.16,people:38767,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"107",km:14.57,people:143512,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"108",km:4.69,people:44965,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"109",km:12.21,people:141200,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"120",km:17.01,people:157942,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"121",km:25.45,people:198303,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"122",km:30.51,people:140740,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"123",km:44.36,people:199936,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"124",km:34.48,people:99517,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"125",km:215.17,people:152520,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"126",km:62.05,people:243939,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"130",km:42.13,people:153256,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"131",km:76.23,people:158662,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"133",km:14.17,people:147289,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"134",km:48.47,people:180926,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"135",km:106.08,people:247384,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"136",km:10.88,people:28956,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"140",km:43.13,people:82116,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"141",km:98.95,people:148677,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"144",km:188.54,people:155925,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"145",km:679.97,people:134029,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"146",km:903.08,people:105582,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"147",km:1847.58,people:150723,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"148",km:953.21,people:31047,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"149",km:1458.49,people:96756,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"152",km:426.09,people:74558,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"153",km:1569.56,people:149260,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"155",km:833.97,people:102857,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"157",km:691.35,people:98597,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"158",km:1925.51,people:136484,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"159",km:1641.07,people:53579,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"162",km:1869.79,people:112872,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"163",km:1150.32,people:147297,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"165",km:432.25,people:103226,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"167",km:1378.50,people:98355,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"168",km:2136.70,people:85257,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"169",km:1409.69,people:43987,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"170",km:1238.84,people:108091,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"171",km:2771.37,people:115624,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"172",km:3978.57,people:136056,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"173",km:2881.29,people:110945,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"174",km:930.07,people:118769,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"175",km:263.33,people:12676,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"180",km:120.27,people:100057,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"181",km:752.55,people:157157,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"182",km:2062.79,people:132874,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"183",km:915.93,people:52678,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"184",km:636.09,people:81798,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"185",km:1589.80,people:86970,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"186",km:23.86,people:5206,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"190",km:1024.28,people:141739,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"192",km:2568.25,people:113931,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"193",km:3030.15,people:129493,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"194",km:495.07,people:18158,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"200",km:4.31,people:13912,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"201",km:4.32,people:34166,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"202",km:6.20,people:88381,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"203",km:4.98,people:35073,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"204",km:15.50,people:9713,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"205",km:16.88,people:55746,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"210",km:223.33,people:223600,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"211",km:130.97,people:108569,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"212",km:737.42,people:143157,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"213",km:976.72,people:159242,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"214",km:719.50,people:153330,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"215",km:349.51,people:73886,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"216",km:573.50,people:154208,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"217",km:1383.51,people:108507,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"220",km:27.50,people:171653,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"221",km:112.17,people:286749,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"222",km:5.88,people:29662,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"223",km:103.05,people:230461,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"224",km:41.80,people:114814,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"225",km:69.99,people:179977,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"226",km:15.19,people:38438,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"227",km:12.57,people:105141,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"228",km:165.15,people:141547,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"229",km:343.43,people:106367,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"235",km:210.94,people:209583,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"236",km:397.60,people:92726,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"237",km:1234.70,people:146329,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"238",km:1173.79,people:135865,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"239",km:1497.16,people:141782,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"241",km:175.12,people:266676,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"242",km:883.35,people:128528,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"243",km:1045.32,people:102134,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"244",km:99.18,people:5308,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"245",km:552.94,people:182311,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"246",km:783.73,people:62346,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"247",km:362.32,people:73111,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"248",km:1274.60,people:107301,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"249",km:854.87,people:166653,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"253",km:452.51,people:109361,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"254",km:379.88,people:175448,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"255",km:1000.17,people:119024,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"256",km:53.82,people:4690,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"257",km:1312.80,people:117085,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"258",km:1467.92,people:110073,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"259",km:672.63,people:56366,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"261",km:893.03,people:262051,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"262",km:428.39,people:52417,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"263",km:478.69,people:127756,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"264",km:962.05,people:106272,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"265",km:503.46,people:75700,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"266",km:1239.91,people:168567,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"267",km:419.29,people:108810,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"268",km:1221.51,people:171644,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"269",km:874.81,people:87197,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"272",km:1182.51,people:112598,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"273",km:1773.37,people:147104,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"274",km:1455.01,people:144350,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"275",km:79.16,people:108055,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"276",km:1180.25,people:102719,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"277",km:810.53,people:204872,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"278",km:223.41,people:20275,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"281",km:42.14,people:44002,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"282",km:91.04,people:260317,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"283",km:112.65,people:141811,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"287",km:204.57,people:116153,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"288",km:628.99,people:168968,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"292",km:182.01,people:69081,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"293",km:2196.10,people:147138,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"294",km:1804.29,people:80378,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"295",km:1375.47,people:88867,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"296",km:1789.53,people:133427,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"301",km:39.57,people:177701,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"304",km:56.46,people:156264,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"305",km:52.42,people:72785,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"306",km:56.42,people:99875,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"308",km:287.77,people:182229,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"309",km:674.15,people:186526,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"310",km:585.16,people:76623,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"311",km:791.68,people:219163,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"312",km:495.31,people:134415,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"313",km:354.45,people:71888,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"315",km:969.84,people:173044,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"316",km:1064.39,people:118347,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"317",km:369.98,people:106394,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"318",km:837.83,people:120645,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"320",km:79.11,people:65358,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"321",km:205.35,people:107554,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"322",km:129.22,people:71565,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"323",km:522.64,people:93179,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"324",km:520.95,people:158227,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"325",km:124.24,people:88538,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"326",km:624.79,people:127400,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"327",km:205.31,people:108782,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"328",km:366.74,people:65002,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"330",km:374.53,people:65767,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"331",km:1315.71,people:280296,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"333",km:308.95,people:169534,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"334",km:289.24,people:72837,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"336",km:190.37,people:267363,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"337",km:290.96,people:128371,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"338",km:217.35,people:73038,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"341",km:108.00,people:190786,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"342",km:559.96,people:168749,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"343",km:1165.93,people:138801,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"344",km:1245.29,people:134057,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"345",km:1236.60,people:118431,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"346",km:519.51,people:50680,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"350",km:753.65,people:159143,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"351",km:511.98,people:35136,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"352",km:660.43,people:98052,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"353",km:553.31,people:121522,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"354",km:595.31,people:153061,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"355",km:229.85,people:84656,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"356",km:506.84,people:115007,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"357",km:760.03,people:131012,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"360",km:253.42,people:96439,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"361",km:1482.75,people:157022,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"362",km:1024.98,people:101701,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"363",km:1270.60,people:104476,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"364",km:696.41,people:78108,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"370",km:116.95,people:115865,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"371",km:1412.33,people:170733,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"372",km:911.93,people:94610,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"373",km:942.96,people:102407,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"374",km:494.65,people:54482,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"375",km:873.13,people:91654,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"376",km:906.40,people:113753,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"381",km:848.06,people:335282,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"382",km:372.46,people:122595,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"383",km:667.41,people:129502,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"384",km:1126.43,people:170391,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"385",km:569.97,people:111207,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"386",km:443.36,people:98415,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"387",km:450.39,people:40144,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"388",km:1378.29,people:150045,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"391",km:660.17,people:280917,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"392",km:1775.36,people:136235,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"393",km:2329.37,people:155975,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"394",km:405.34,people:51632,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"395",km:1476.26,people:87961,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"396",km:2373.86,people:79742,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"402",km:42.68,people:225523,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"404",km:78.70,people:146508,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"405",km:55.52,people:156421,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"406",km:129.67,people:155945,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"407",km:92.54,people:151457,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"408",km:130.28,people:125050,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"410",km:49.78,people:116655,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"411",km:89.37,people:62448,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"412",km:31.29,people:76195,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"413",km:387.43,people:145242,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"414",km:99.49,people:150586,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"415",km:285.30,people:178411,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"417",km:91.11,people:75005,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"418",km:305.32,people:126839,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"421",km:42.96,people:121105,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"422",km:41.58,people:124390,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"423",km:85.83,people:97703,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"424",km:136.93,people:59020,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"425",km:102.73,people:106957,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"426",km:80.65,people:131175,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"427",km:70.20,people:81369,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"428",km:73.98,people:110517,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"429",km:74.77,people:35018,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"441",km:50.49,people:171139,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"442",km:90.33,people:163741,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"443",km:139.87,people:236489,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"445",km:110.88,people:160868,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"446",km:51.48,people:155043,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"447",km:50.12,people:116393,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"448",km:95.48,people:246176,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"451",km:65.56,people:233333,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"452",km:84.14,people:131921,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"453",km:60.61,people:201204,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"454",km:91.25,people:166948,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"455",km:119.58,people:79998,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"456",km:85.36,people:148496,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"457",km:416.86,people:245610,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"458",km:104.85,people:258891,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"459",km:35.92,people:74097,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"460",km:21.59,people:87235,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"461",km:55.50,people:122361,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"462",km:271.75,people:194438,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"463",km:499.91,people:154095,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"464",km:599.06,people:168581,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"465",km:450.62,people:166432,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"470",km:36.28,people:106340,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"471",km:108.61,people:232199,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"472",km:87.85,people:149757,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"474",km:206.05,people:172702,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"475",km:574.00,people:158959,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"476",km:567.72,people:127091,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"477",km:5.10,people:47216,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"478",km:200.40,people:225935,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"479",km:144.12,people:79703,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"481",km:303.14,people:289790,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"482",km:664.42,people:172463,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"483",km:928.54,people:152387,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"484",km:975.77,people:182435,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"485",km:340.26,people:131113,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"486",km:757.00,people:143870,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"487",km:480.72,people:80565,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"490",km:119.68,people:153924,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"491",km:728.99,people:164251,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"492",km:130.92,people:34498,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"493",km:537.30,people:117292,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"494",km:1433.54,people:183792,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"495",km:1048.19,people:148797,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"496",km:1199.91,people:134807,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"497",km:1468.42,people:136181,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"498",km:1049.37,people:122906,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"501",km:356.73,people:166088,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"502",km:117.47,people:102695,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"503",km:230.50,people:182921,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"506",km:16.58,people:121677,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"507",km:98.57,people:189226,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"508",km:44.65,people:146648,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"509",km:76.02,people:189938,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"510",km:51.86,people:144852,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"511",km:117.18,people:213660,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"513",km:106.30,people:177105,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"514",km:151.95,people:135734,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"515",km:609.98,people:148063,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"516",km:266.84,people:97129,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"517",km:241.64,people:73201,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"520",km:160.97,people:236442,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"521",km:312.57,people:119230,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"522",km:174.17,people:110945,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"523",km:636.73,people:185579,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"524",km:363.98,people:146373,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"525",km:322.70,people:121665,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"531",km:108.03,people:241228,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"532",km:32.99,people:64530,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"533",km:271.63,people:138452,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"534",km:195.05,people:72009,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"535",km:771.26,people:99477,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"536",km:130.83,people:69035,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"537",km:299.15,people:186001,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"538",km:563.98,people:235472,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"539",km:1034.96,people:123657,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"542",km:192.49,people:113491,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"543",km:577.26,people:89206,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"544",km:990.78,people:93210,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"545",km:1718.35,people:130556,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"546",km:1230.54,people:76002,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"551",km:97.99,people:200379,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"552",km:667.69,people:224485,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"554",km:866.19,people:130263,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"555",km:597.83,people:129322,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"556",km:242.87,people:26304,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"557",km:693.93,people:77610,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"560",km:105.60,people:107868,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"561",km:210.40,people:79599,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"562",km:861.60,people:137413,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"563",km:812.27,people:105922,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"564",km:530.79,people:112077,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"565",km:241.37,people:103499,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"566",km:190.21,people:59721,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"567",km:605.88,people:85956,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"568",km:619.15,people:53300,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"570",km:114.61,people:99246,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"572",km:535.30,people:136512,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"573",km:1073.24,people:105634,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"574",km:428.01,people:98092,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"575",km:369.85,people:95688,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"576",km:431.08,people:62248,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"580",km:116.80,people:134267,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"581",km:43.25,people:53758,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"582",km:140.52,people:108510,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"583",km:131.90,people:87946,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"584",km:72.37,people:96364,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"585",km:389.69,people:138737,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"586",km:193.26,people:129933,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"587",km:273.95,people:130498,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"588",km:284.81,people:57696,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"590",km:226.35,people:176181,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"591",km:123.87,people:110866,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"592",km:234.39,people:88536,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"593",km:833.09,people:177517,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"594",km:324.02,people:165304,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"595",km:857.56,people:175318,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"596",km:231.77,people:21108,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"597",km:99.35,people:46259,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"598",km:618.96,people:96630,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"599",km:790.28,people:79839,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"603",km:56.65,people:235507,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"604",km:74.61,people:218592,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"605",km:79.69,people:110229,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"611",km:318.98,people:145029,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"612",km:438.46,people:112910,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"613",km:115.90,people:84457,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"614",km:121.33,people:92924,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"630",km:44.76,people:113241,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"631",km:140.00,people:143310,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"632",km:53.28,people:69640,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"633",km:98.27,people:76583,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"634",km:138.32,people:142915,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"635",km:451.56,people:177197,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"636",km:1066.64,people:148042,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"637",km:291.66,people:161529,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"638",km:712.14,people:142497,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"639",km:348.21,people:54871,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"642",km:122.16,people:143607,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"643",km:361.73,people:171103,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"644",km:71.74,people:19625,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"645",km:293.27,people:123121,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"646",km:320.37,people:133919,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"647",km:528.83,people:79880,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"648",km:305.88,people:99771,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"651",km:70.21,people:163943,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"652",km:201.93,people:125336,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"653",km:545.13,people:99257,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"654",km:182.14,people:147950,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"655",km:576.26,people:162480,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"656",km:238.46,people:52779,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"657",km:115.86,people:110543,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"658",km:63.77,people:76754,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"659",km:37.47,people:103959,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"661",km:167.46,people:175780,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"662",km:127.26,people:84903,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"663",km:231.52,people:121240,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"664",km:457.51,people:141732,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"665",km:321.59,people:144506,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"666",km:871.56,people:164382,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"667",km:424.82,people:157225,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"668",km:679.67,people:142974,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"669",km:745.35,people:124475,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"670",km:186.90,people:175834,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"671",km:258.35,people:114688,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"672",km:367.34,people:135621,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"673",km:362.23,people:124175,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"674",km:444.03,people:116798,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"675",km:333.19,people:126519,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"676",km:369.16,people:133615,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"677",km:540.33,people:56912,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"678",km:355.62,people:28214,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"681",km:45.48,people:142276,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"682",km:43.71,people:64827,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"683",km:55.78,people:83003,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"685",km:99.31,people:77203,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"686",km:166.49,people:58866,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"687",km:208.13,people:138468,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"688",km:19.38,people:11880,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"691",km:236.62,people:238718,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"692",km:207.61,people:109854,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"694",km:491.80,people:106774,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"695",km:71.54,people:34602,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"701",km:47.54,people:194728,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"703",km:47.92,people:135017,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"704",km:40.14,people:114251,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"705",km:64.14,people:117384,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"706",km:10.95,people:24411,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"707",km:92.62,people:123894,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"708",km:52.17,people:68217,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"710",km:193.87,people:157240,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"711",km:250.57,people:106799,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"712",km:252.09,people:141984,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"713",km:127.92,people:130003,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"714",km:21.19,people:19148,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"715",km:374.00,people:106777,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"716",km:188.31,people:191201,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"717",km:216.17,people:101410,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"720",km:107.91,people:82543,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"721",km:768.35,people:191533,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"722",km:880.47,people:131375,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"723",km:465.31,people:90140,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"724",km:605.79,people:119939,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"725",km:974.12,people:116875,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"726",km:181.38,people:110467,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"727",km:117.86,people:127210,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"728",km:310.63,people:56874,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"730",km:234.91,people:153096,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"731",km:106.98,people:30293,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"732",km:258.45,people:146814,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"733",km:286.67,people:58829,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"734",km:1087.10,people:176475,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"735",km:445.65,people:132716,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"736",km:297.20,people:115864,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"737",km:117.79,people:163908,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"740",km:100.23,people:116038,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"741",km:221.21,people:96490,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"742",km:470.83,people:99267,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"743",km:402.64,people:180931,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"744",km:350.57,people:35865,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"745",km:1186.98,people:155789,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"746",km:641.44,people:97250,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"747",km:632.48,people:61831,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"748",km:556.19,people:115507,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"749",km:321.09,people:69928,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"750",km:305.26,people:84618,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"751",km:123.06,people:126135,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"752",km:225.08,people:80792,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"753",km:567.17,people:112180,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"754",km:198.55,people:62799,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"761",km:118.59,people:234609,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"762",km:189.24,people:136305,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"763",km:277.96,people:108470,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"764",km:199.71,people:100251,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"765",km:488.01,people:120569,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"766",km:311.40,people:120989,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"767",km:436.51,people:119402,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"768",km:867.93,people:160133,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"776",km:154.26,people:90817,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"777",km:1112.98,people:152188,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"778",km:475.62,people:119031,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"779",km:402.14,people:106511,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"780",km:349.58,people:117174,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"781",km:896.37,people:103726,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"782",km:427.41,people:110033,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"783",km:300.88,people:69240,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"784",km:93.21,people:89772,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"785",km:454.10,people:94101,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"786",km:417.88,people:86758,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"787",km:212.14,people:47242,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"790",km:1.35,people:8288,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"791",km:275.03,people:261815,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"792",km:921.04,people:158417,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"793",km:478.69,people:130647,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"794",km:216.65,people:37024,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"795",km:182.28,people:105999,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"796",km:525.32,people:106347,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"797",km:632.01,people:116019,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"798",km:914.16,people:71734,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"803",km:8.72,people:74917,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"804",km:1.22,people:23960,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"805",km:2.90,people:14215,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"806",km:21.65,people:160320,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"807",km:3.80,people:58894,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"808",km:18.44,people:106676,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"809",km:78.23,people:176327,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"812",km:49.16,people:99390,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"813",km:22.67,people:141536,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"814",km:13.40,people:59586,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"815",km:17.72,people:125343,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"816",km:14.40,people:108634,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"817",km:15.92,people:75137,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"818",km:22.00,people:69434,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"819",km:20.67,people:54034,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"820",km:284.22,people:101628,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"821",km:158.61,people:149957,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"822",km:600.23,people:156737,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"823",km:668.96,people:130174,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"824",km:1348.79,people:102548,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"825",km:273.98,people:56768,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"830",km:483.72,people:168559,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"831",km:541.87,people:61215,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"832",km:551.64,people:70301,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"833",km:944.94,people:127433,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"834",km:808.57,people:83235,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"835",km:576.26,people:64157,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"836",km:1061.59,people:102981,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"837",km:591.88,people:54990,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"840",km:1198.80,people:188004,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"841",km:1288.86,people:127181,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"843",km:954.86,people:96172,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"844",km:719.05,people:119277,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"845",km:971.49,people:126740,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"850",km:474.51,people:194929,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"851",km:722.31,people:69528,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"852",km:876.01,people:173534,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"853",km:555.25,people:131280,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"854",km:725.98,people:110447,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"855",km:230.54,people:142022,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"856",km:598.26,people:110846,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"857",km:208.99,people:105114,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"861",km:148.64,people:267780,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"863",km:263.93,people:140685,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"864",km:930.70,people:136236,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"865",km:937.17,people:105770,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"866",km:1427.67,people:159905,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"867",km:640.12,people:60995,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"868",km:797.92,people:132414,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"869",km:937.10,people:112297,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"874",km:823.16,people:148776,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"875",km:901.73,people:78159,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"876",km:1128.87,people:139499,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"877",km:1027.11,people:141719,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"880",km:279.10,people:121825,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"881",km:336.03,people:80245,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"882",km:840.23,people:180589,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"883",km:842.47,people:101847,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"884",km:1382.25,people:186365,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"885",km:363.07,people:34289,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"886",km:780.90,people:106004,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"887",km:21.71,people:9353,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"890",km:118.77,people:116833,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"891",km:819.05,people:123794,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"892",km:515.34,people:163982,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"893",km:490.92,people:84747,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"894",km:510.86,people:67598,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"895",km:851.28,people:159293,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"896",km:237.11,people:25012,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"904",km:238.04,people:486607,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"905",km:765.47,people:228287,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"906",km:178.53,people:25834,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"907",km:63.39,people:115525,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"910",km:360.57,people:189704,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"911",km:777.66,people:131022,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"912",km:1046.93,people:127793,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"913",km:838.55,people:130884,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"914",km:926.54,people:80852,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"915",km:1101.35,people:145408,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"916",km:707.25,people:49595,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"917",km:1251.93,people:115240,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"918",km:145.53,people:9971,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"922",km:1325.53,people:141981,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"923",km:1310.49,people:126259,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"924",km:552.53,people:56953,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"925",km:670.62,people:47719,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"926",km:890.29,people:101880,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"927",km:595.68,people:38624,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"930",km:467.76,people:203619,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"931",km:1470.75,people:171685,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"933",km:869.94,people:82750,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"934",km:1277.90,people:110104,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"940",km:736.97,people:132574,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"941",km:1137.78,people:102590,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"942",km:938.28,people:75068,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"943",km:1076.41,people:127133,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"944",km:1051.56,people:142239,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"945",km:1287.80,people:113567,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"950",km:58.06,people:44790,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"951",km:975.26,people:106379,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"952",km:231.79,people:26897,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"953",km:521.09,people:63280,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"954",km:798.69,people:135136,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"955",km:322.42,people:28571,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"956",km:906.56,people:87832,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"957",km:249.22,people:14209,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"960",km:54.57,people:70651,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"961",km:1779.64,people:182134,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"962",km:720.64,people:97543,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"963",km:608.88,people:66076,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"964",km:407.66,people:98724,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"965",km:313.71,people:47664,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"970",km:87.67,people:124381,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"971",km:63.57,people:11067,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"972",km:1199.10,people:169698,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"973",km:634.83,people:85458,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"974",km:974.13,people:167470,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"975",km:657.28,people:70255,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"976",km:1036.73,people:99201,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"977",km:1589.42,people:125990,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"978",km:798.90,people:91622,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"979",km:1172.90,people:113001,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"985",km:904.82,people:127182,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"986",km:1538.76,people:144275,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"987",km:373.15,people:32305,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"990",km:269.56,people:200963,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"991",km:344.04,people:31549,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"993",km:507.06,people:59437,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"994",km:605.93,people:104319,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"995",km:267.39,people:40035,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"996",km:558.34,people:52146,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"997",km:1376.15,people:131096,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"998",km:1562.09,people:216965,country:"-NZ1A7J2ggQg2VAyqqxA"},
{zip:"999",km:1009.30,people:109309,country:"-NZ1A7J2ggQg2VAyqqxA"}];