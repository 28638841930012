import { equalTo, get, getDatabase, orderByChild, query, ref, update } from 'firebase/database';
import React, { useEffect, useState } from 'react'
import Loading from '../../modals/Loading';
import Error from '../../modals/Error';
import { Accordion, Alert, Form, Row, Table, Button, ButtonGroup, Col, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loader } from '../../../root/redux/actions/database/loader';
import { defaultRightsList } from '../products/defaultData';
import Badge from 'react-bootstrap/Badge';
import { getFunctions, httpsCallable } from 'firebase/functions';
import ExistingContract from './ExistingContract';

const Contract = ({obj = null, objId = null, goBack}) => {

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;

  const [persons, setPersons] = useState({});
  const {products, productsLoaded, productsError, settings} = useSelector(state => state.app);
  const nof = settings.nof;
  const [licenses, setLicenses] = useState({});
  const [emailError, setEmailError] = useState([]);
  const [loading, setLoading] = useState(true);
  const [waitForServer, setWaitingForServer] = useState(false);
  const [created, setCreated] = useState(false);
  const [createdError, setCreatedError] = useState(false);
  const [error, setError] = useState(false);
  const [emailChanges, setEmailChanges] = useState({});
  const [billingEmail, setBillingEmail] = useState("");
  const [billingForm, setBillingForm] = useState("anual");
  const [billingEmailOther, setBillingEmailOther] = useState("");
  const [billingEmailError, setBillingEmailError] = useState(false);
  const [isDirty, setDirty] = useState({});
  const dispatch = useDispatch();

  const handleEmailChange = e => {
    const personKey = e.target.name
    setEmailChanges(prev => ({...prev, [personKey]: e.target.value }));
    setDirty(prev => ({...prev, [personKey]: true}));
  }
  const handleSaveEMail = (personKey) => {
    update(ref(getDatabase(), "persons/"+personKey), {email: emailChanges[personKey]})
    .then(() => {
      setDirty(prev => ({...prev, [personKey]: false}));
      setPersons((prev) => ({
        ...prev,
        [personKey]: {
          ...prev[personKey],
          email: emailChanges[personKey],
        },
      }));
    })
    .catch((err) => console.error(err));
  }

  useEffect(() => {
    if(loading) {
      get(query(ref(getDatabase(), "persons"),orderByChild("contactId"),equalTo(objId)))
      .then((results) => {
        if(results !== null) {
          setPersons(results.exportVal());
          const resultObj = results.exportVal();
          const emailObj = (obj?.email ? {client: obj.email} : {});
          const dirtyObj = {};
          for (const key in resultObj) {
            emailObj[key] = resultObj[key]?.email || "";
            dirtyObj[key] = false;
          }
          setBillingEmail(Object.values(emailObj)[0]);
          setEmailChanges(emailObj);
          setDirty(dirtyObj);
        }
        setError(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setError(true);
      });
    }
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if(!productsLoaded) {
      dispatch(loader("products"));
    }
    // eslint-disable-next-line
  }, [productsLoaded]);

  if(error || productsError !== null) return <Error />;
  if(loading || !productsLoaded) return <Loading />;

  const hasPersons = (persons!==null && Object.keys(persons).length > 0);
  
  const grantAccess = (personId, productCode = null) => {
    let obj = { ...licenses };
    if (obj[personId] === undefined) {
      obj[personId] = {};
    }
    if (productCode) {
      obj[personId][productCode] = {};
      for (const rightKey in products[productCode].rights) {
        const right = products[productCode].rights[rightKey];
        const highestRight = Math.max(...Object.values(right.right));
        obj[personId][productCode][right.code] = highestRight >= 1 ? highestRight : 1;
      }
      const requiredProducts = products[productCode].required || [];
      requiredProducts.forEach((requiredProduct) => {
        if (!obj[personId][requiredProduct]) {
          obj[personId][requiredProduct] = {};
          for (const rightKey in products[requiredProduct].rights) {
            const right = products[requiredProduct].rights[rightKey];
            const highestRight = Math.max(...Object.values(right.right));
            obj[personId][requiredProduct][right.code] = highestRight >= 1 ? highestRight : 1;
          }
        }
      });
    } else {
      for (const product in products) {
        const requiredProducts = products[product].required || [];
        if (requiredProducts.every((requiredProduct) => obj[personId][requiredProduct])) {
          obj[personId][product] = {};
          for (const rightKey in products[product].rights) {
            const right = products[product].rights[rightKey];
            const highestRight = Math.max(...Object.values(right.right));
            obj[personId][product][right.code] = highestRight >= 1 ? highestRight : 1;
          }
        }
      }
    }
    setLicenses(obj);
  };

  const removeAccess = (personId, productCode = null) => {
    let obj = { ...licenses };
    if (productCode) {
      if (obj[personId] && obj[personId][productCode]) {
        delete obj[personId][productCode];
        if (Object.keys(obj[personId]).length === 0) {
          delete obj[personId];
        }
      }
      for (const product in products) {
        const requiredProducts = products[product].required || [];
        if (requiredProducts.includes(productCode) && obj[personId][product]) {
          delete obj[personId][product];
        }
      }
    } else {
      delete obj[personId];
    }
    setLicenses(obj);
  }
  
  const handleChange = (personId, productCode, rightCode, selectedRight) => {
    const updatedObj = { ...licenses };
  
    if (!updatedObj[personId]) {
      updatedObj[personId] = {};
    }
    if (!updatedObj[personId][productCode]) {
      updatedObj[personId][productCode] = {};
    }
  
    updatedObj[personId][productCode][rightCode] = selectedRight;
    if (Object.values(updatedObj[personId][productCode]).every((right) => right === 0)) {
      delete updatedObj[personId][productCode];
    }
  
    setLicenses(updatedObj);
  }

  const formatDate = (date, withoutYear = false) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear().toString().slice(2);
    return day + "." + month + "." + (withoutYear ? "" : year);
  };
  
  const billingInformation = () => {

    const today = new Date();
  
    const firstBillingDate = new Date(today);
    firstBillingDate.setDate(firstBillingDate.getDate() + 7);
    const cancleDate = new Date(today);
    cancleDate.setDate(cancleDate.getDate() + 14)
    const daysInCurrentMonth = new Date(today.getFullYear(), today.getUTCMonth() + 1, 0).getDate();
    const daysMultiplier = (daysInCurrentMonth - today.getDate()) / daysInCurrentMonth;
    const daysInBilling = daysInCurrentMonth - today.getDate();
    
  
    const monthsMultiplier = (cancleDate.getMonth() === today.getMonth() ? 0 : 1);
    
    const nextBillingDate = new Date();
    nextBillingDate.setMonth(cancleDate.getMonth() + 1);
    nextBillingDate.setDate(1);
  
    const nextAnualBillingDate = new Date();
    nextAnualBillingDate.setFullYear(today.getFullYear() + 1);

    const billingEndDate = new Date();
    billingEndDate.setMonth(today.getMonth() + (monthsMultiplier > 0 ? 2 : 1));
    billingEndDate.setDate(0);

    const anualBillingEndDate = nextAnualBillingDate;
    anualBillingEndDate.setDate(anualBillingEndDate.getDate() - 1);
    return {
      today: formatDate(today),
      firstBillingDate: formatDate(firstBillingDate),
      cancleDate: formatDate(cancleDate),
      monthsMultiplier,
      daysMultiplier,
      daysInBilling,
      nextBillingDate: (billingForm === "anual" ? formatDate(nextAnualBillingDate) : formatDate(nextBillingDate)),
      billingEndDate: (billingForm === "anual" ? formatDate(anualBillingEndDate) : formatDate(billingEndDate)),
    };
  };
   
  const billingInfo = billingInformation();

  const calculateBillingAmount = () => {
    let amount = 0;
    let selectedLicenses = {};
    let benefits = {};
    for ( const person in licenses ) {
      for (const license in licenses[person]) {
        
        const product = products[license];

        amount = amount + ( product.price * 1);
        
        if(!selectedLicenses.hasOwnProperty(license)) {
          selectedLicenses[license] = {name: product.name, qty: 0, ep: product.price * 1, gp: 0, users: []};
        }
        selectedLicenses[license].users.push(persons[person]?.vorname + " " + persons[person]?.name);
        selectedLicenses[license].qty = selectedLicenses[license].qty + 1;
        selectedLicenses[license].gp = selectedLicenses[license].gp + ( product.price * 1 );

        for ( const id in products[license].includes ) {
          const benefit = products[license].includes[id];
          if (!benefits.hasOwnProperty(benefit.key)) {
            benefits[benefit.key] = {qty: 0, name: benefit.name, step: benefit.step, format: benefit.format};
          }
          benefits[benefit.key].qty = benefits[benefit.key].qty + (benefit.qty * 1);
        }
      }
    }
    return {
      monthlyBillingAmount: amount,
      anualBillingAmount: (amount * 11),
      selectedLicenses,
      benefits
    };
  }

  const { monthlyBillingAmount, benefits, selectedLicenses } = calculateBillingAmount();

  const submitContract = () => {
    setCreatedError(false);
    setEmailError([]);
    setBillingEmailError(false);

    if(Object.keys(licenses).length === 0) return;

    let invalidEmail = [];
    for (const person in licenses) {
      if ( !persons[person].hasOwnProperty("email") ||
          persons[person].email === "" ||
          persons[person].email === null ||
          !emailRegex.test(persons[person].email)) {
          invalidEmail.push(person);  
        }
    }
    if (invalidEmail.length > 0) {
      setEmailError(invalidEmail);
      return;
    }
    else if (billingEmail === "new" && !emailRegex.test(billingEmailOther)) {
      setBillingEmailError(true);
      return;
    }
    setWaitingForServer(true);
    if(!window.confirm("Hinweis:\nDie Daten werden mit Klick auf \"OK\" unwiderruflich an den WerkAPP Server übermittelt. Der Kunde erhält eine E-Mail mit der Vertragsbestätigung.")) {
      setWaitingForServer(false);
      return;
    }
    
    const timestamp = (date, unix = false) => {
      const [day, month, year] = date.split(".");
      const timeStamp = new Date("20"+year, (month * 1 ) - 1, (day * 1));
      return (unix ? timeStamp.getTime() : ("20" + year + "" + month + "" + day) * 1);
    }

    const contractData = {
      contact: obj,
      contactId: objId,
      licenses,
      billingEmail: (billingEmail === "new" ? billingEmailOther : billingEmail),
      contract: billingForm,
      firstBillAmount: (billingForm === "anual" ? (monthlyBillingAmount * 11) : ( billingInfo.monthsMultiplier * monthlyBillingAmount ) + ( billingInfo.daysMultiplier * monthlyBillingAmount )),
      benefits,
      persons: Object.keys(persons).reduce((filteredObj, key) => Object.keys(licenses).includes(key) ? { ...filteredObj, [key]: persons[key] } : filteredObj, {}),
      firstBillingDate: timestamp(billingInfo.firstBillingDate),
      cancleDate: timestamp(billingInfo.cancleDate),
      startDate: timestamp(billingInfo.today),
      nextBillingDate: timestamp(billingInfo.nextBillingDate),
    }
    const createClient = httpsCallable(getFunctions(), "Sales_CreateClient");
    createClient(contractData)
    .then((result) => {
      console.log(result);
      if(result.data.status === "success") {
        setCreated(true);
        setWaitingForServer(false);
      } else {
        setCreated(false);
        setCreatedError(true);
        setWaitingForServer(false);
      }
    })
    .catch(err => {
      setCreatedError(true);
      console.error(err);
      setWaitingForServer(false);
    });
  }
  const orderBadges = (items) => {
    const arr = [];
    for (const item in items) {
      arr.push([products[item].short, products[item].name]);
    }
    return arr.sort(([a], [b]) => a.localeCompare(b));
  }

  if(waitForServer) {
    return <Alert variant="primary" style={{height:"400px", display: "flex", alignItems: "center", justifyContent: "center"}}>Einen Moment, die Daten werden übertragen...</Alert>;
  }
  if(created) {
    return <Alert variant="success">WerkAPP wurde erfolgreich eingerichtet!<br/><br/><Button variant="success" onClick={goBack}>Zurück</Button></Alert>
  }
  if(obj?.status === "Aktiv") {
    return <ExistingContract obj={obj} objId={objId} goBack={goBack} />
  }

  return (
    <>
      {
        createdError && <Alert variant="danger">Der Kunde konnte nicht erstellt werden... Bitte wende dich an den Support oder versuche es später noch einmal.</Alert>
      }
      <h5>Vertragsmanagment</h5>
      <p>Aktueller Status: { (obj?.status && obj?.status !== "Kontakt") ? obj?.status : "Kein Vertrag"}</p>
      {
        (!hasPersons) && <Alert>Es sind keine Personen vorhanden. Bitte legen Sie erst welche an.<br/><br/><Button onClick={goBack}>Zurück</Button></Alert>
      }
      {
        (hasPersons && obj?.status !== "Aktiv") && <>
        <Form.Group className="mt-4 mb-2">
        <Form.Text>Rechnungsempfänger:</Form.Text>
        <InputGroup style={{maxWidth:"500px"}}>
          <Form.Select value={billingEmail} onChange={e => setBillingEmail(e.target.value)}>
            {
              (obj?.email) && <option value={obj.email}>{obj.email}</option>
            }
            {
              Object.values(persons).filter((a) => a?.email && a.email !== "").map(({email}, index) => <option value={email} key={index}>{email}</option>)
            }
            <option value="new">Andere</option>
          </Form.Select>
          { (billingEmail === "new") && <Form.Control isInvalid={(billingEmailError || (billingEmailOther.length > 5 && !emailRegex.test(billingEmailOther)))} style={{width:"55%"}} type="email" value={billingEmailOther} onChange={e => setBillingEmailOther(e.target.value)} />
          }
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Text>Abrechnung</Form.Text>
        <Form.Select style={{maxWidth:"500px"}} value={ billingForm } onChange={e => setBillingForm(e.target.value)}>
          <option value="anual">Jährlich (1 Monat geschenkt)</option>
          <option vlaue="monthly">Monatlich</option>
        </Form.Select>
      </Form.Group>
        <Accordion>
        {
          Object.entries(persons).map(([personKey, person], _) => 
          <Accordion.Item key={personKey} eventKey={personKey} className={!person?.email  && "errorAccordion"}>
            <Accordion.Header onClick={(e) => e.stopPropagation()}>
              <Col>
              <span className={(person?.email ? "fw-semibold" : "fw-semibold text-danger")}>
              { (person?.anrede ? person?.anrede + " " : "") }
              {person?.name}
              {(person?.vorname) ? ", " + person.vorname : ""}</span>
              </Col>
              <Col>
               {
                person?.email ? <ButtonGroup>
                  <div className="btn btn-outline-danger btn-sm" onClick={(e) => { e.stopPropagation(); removeAccess(personKey); }}>Kein Zugriff</div>
                  <div className="btn btn-outline-primary btn-sm" onClick={(e) => { e.stopPropagation(); grantAccess(personKey); }}>Vollzugriff</div>
                </ButtonGroup>: "E-Mail Adresse ungültig!"
                }
              </Col>
              <Col>
              {
                licenses[personKey] && orderBadges(licenses[personKey]).map(([short, name], index) => 
                  <Badge bg="primary" key={index} title={name} style={{marginRight:"3px"}}>{ short }</Badge>
                )
              }
              </Col>
            </Accordion.Header>
            <Accordion.Body>
              <Form.Group className="mb-3">
                <Form.Text>E-Mail</Form.Text>
                <InputGroup>
                  <Form.Control type="email" name={personKey} value={emailChanges[personKey]} onChange={handleEmailChange} style={{maxWidth:"300px"}}/>
                  <Button disabled={
                    !isDirty[personKey] || // Button ist deaktiviert, wenn isDirty[personKey] false ist
                    emailChanges[personKey].length < 6 || // Button ist deaktiviert, wenn die E-Mail-Adresse weniger als 6 Zeichen hat
                    !emailRegex.test(emailChanges[personKey]) // Button ist deaktiviert, wenn die E-Mail-Adresse die Regex nicht erfüllt
                  } onClick={e => handleSaveEMail(personKey)}>Speichern</Button>
                </InputGroup>
              </Form.Group>
              {
                person?.email && <Accordion>
                {
                  (products !== null) && Object.entries(products).map(([productKey, product], _) => <Accordion.Item 
                    key={productKey}
                    eventKey={productKey}>
                      <Accordion.Header onClick={(e) => e.stopPropagation()}>
                        <Col><span className="fw-semibold">{ product.name }</span></Col>
                        <Col>
                        <ButtonGroup className="mx-2">
                          <div className="btn btn-outline-danger btn-sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              removeAccess(personKey, productKey);
                            }}
                          >
                            Kein Zugriff
                          </div>
                          <div className="btn btn-outline-primary btn-sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              grantAccess(personKey, productKey);
                            }}
                          >
                            Vollzugriff
                          </div>
                        </ButtonGroup>
                          </Col>
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <Table flush={true} bordered striped className="mb-0">
                          <thead>
                            <tr>
                              <th width="65%">Berechtigung</th>
                              <th>{person?.name}{(person?.vorname) ? ", " + person.vorname : ""}</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            product.rights.map((right, index) => <tr key={personKey + '_' + productKey + '_' + index}>
                              <td valign="middle">{right.description}</td>
                              <td>
                                <Form.Select
                                  value={licenses[personKey]?.[productKey]?.[right.code] || ''}
                                  onChange={(e) => handleChange(personKey, productKey, right.code, e.target.value)}>
                                  {
                                    right.right.map((rk, index) => <option key={index} value={rk}>{ defaultRightsList[rk] }</option>)
                                  }
                                </Form.Select>
                              </td>
                            </tr>)
                          }
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                    )
                }
                <Accordion.Header></Accordion.Header>
              </Accordion>
              }
            </Accordion.Body>
          </Accordion.Item>
          )
        }
        </Accordion>
      <Table bordered striped className="my-3">
        <thead>
          <tr>
            <th>Services</th>
            <th style={{textAlign:"right"}}>Menge</th>
            <th style={{textAlign:"right"}}>EP</th>
            <th width="20%" style={{textAlign:"right"}}>GP</th>
          </tr>
        </thead>
        <tbody>
          {
            ( Object.keys(selectedLicenses).length > 0) && Object.values(selectedLicenses).map((license, index) => <tr key={index}>
              <td>{ license.name}<br/>
              {
                license.users.map((user, userIndex) => <p className="d-inline" style={(userIndex === 0) ? {marginLeft:"15px",fontSize:"14px", opacity: 0.7} : {fontSize:"14px", opacity: 0.7}} key={userIndex}>{ userIndex > 0 && ", "}{user}</p>)
              }</td>
              <td align="end">{ license.qty }</td>
              <td align="end">{ license.ep.toLocaleString("de-DE", nof) } €</td>
              <td align="end">{ license.gp.toLocaleString("de-DE", nof) } €</td>
            </tr>)
          }
          {
            ( Object.keys(selectedLicenses).length > 0) && 
            <>
            <tr>
              <td colSpan="4" className="fw-semibold">Inklusive Volumen</td>
            </tr>
            { Object.values(benefits).map((benefit, index) => <tr key={index}>
              <td>{ benefit.name}</td>
              <td align="end">{ ((benefit.step * 1) < 1) ? (benefit.qty * 1).toLocaleString("de-DE", nof) : (benefit.qty * 1).toLocaleString("de-DE", {...nof, minimumFractionDigits: 0, maximumFractionDigits: 0}) }</td>
              <td>{ benefit.format}</td>
              <td align="end"><i>inklusive</i></td>
            </tr>)}</>
          }
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="3" align="right">
              <span className="fw-semibold">Erste Rechnungslegung am { billingInfo.firstBillingDate }</span><br/>
              <span style={{fontSize:"14px"}}>{ billingInfo.today } bis einschließlich { billingInfo.billingEndDate }{ (billingForm === "anual" ? "" : (" entspricht:" + ((billingInfo.monthsMultiplier > 0) ? " 1 Monat und ":" ") + (billingInfo.daysInBilling > 1 ? billingInfo.daysInBilling + " Tage" : billingInfo.daysInBilling + " Tag")))}</span>
            </td>
            <td align="end">
              <span className="fw-semibold">{  billingForm === "anual" ? (monthlyBillingAmount * 11).toLocaleString("de-DE",nof) : (( billingInfo.monthsMultiplier * monthlyBillingAmount ) + ( billingInfo.daysMultiplier * monthlyBillingAmount )).toLocaleString("de-DE", nof) } €</span><br/>
              <span style={{fontSize:"14px", opacity:".5"}}>inkl 19% USt. {  billingForm === "anual" ? ((monthlyBillingAmount * 11) * 1.19).toLocaleString("de-DE",nof) : ((( billingInfo.monthsMultiplier * monthlyBillingAmount ) + ( billingInfo.daysMultiplier * monthlyBillingAmount )) * 1.19).toLocaleString("de-DE", nof) } €</span></td>
          </tr>
          {
            billingForm !== "anual" &&
          <tr>
            <td colSpan="3" align="right">
              <span className="fw-semibold">Monatliche Gebühr ab dem { billingInfo.nextBillingDate }</span><br/>
              <span style={{fontSize:"14px"}}>Rechnungslegung erfolgt jeweils zum 1. des Monats</span>
            </td>
            <td align="end"><span className="fw-semibold">{ monthlyBillingAmount.toLocaleString("de-DE",nof)} €</span><br/>
            <span style={{fontSize:"14px", opacity:".5"}}>inkl 19% USt. { (monthlyBillingAmount * 1.19).toLocaleString("de-DE",nof)} €</span></td>
          </tr>
          }
          <tr>
            <td colSpan="2" className="fw-semibold" style={{fontSize:"12px", opacity:".25"}}>
              APE: { (billingForm === "anual" ? (monthlyBillingAmount * 0.6).toFixed(2) : ((( billingInfo.monthsMultiplier * monthlyBillingAmount ) + ( billingInfo.daysMultiplier * monthlyBillingAmount )) * 0.2).toFixed(2))}
              &nbsp;|&nbsp;
              UPM: { (monthlyBillingAmount * 0.1).toFixed(2) }
              {
                (billingForm === "anual") && <>&nbsp;|&nbsp;UPJ: { (monthlyBillingAmount * 0.1 * 11).toFixed(2) }</>
              }
              &nbsp;|&nbsp;JHS: {
                (billingForm === "anual" ? (monthlyBillingAmount * 1.7).toFixed(2) + " - " + (monthlyBillingAmount * 1.8).toFixed(2) : ((monthlyBillingAmount * 1.2) + ((( billingInfo.monthsMultiplier * monthlyBillingAmount ) + ( billingInfo.daysMultiplier * monthlyBillingAmount )) * 0.2)).toFixed(2))
              }
            </td>
            <td colSpan="2" align="end" className="fw-semibold" style={{fontSize:"14px", opacity:".5"}}>
            Kostenfrei kündbar bis: { billingInfo.cancleDate }
            </td>
          </tr>
        </tfoot>
      </Table>
      {
        emailError.length > 0 && <Alert variant="danger">
          <h6>Bei folgenden Personen ist keine gültige E-Mail Adresse vorhanden:</h6>
          <ul>
          {
            emailError.map((person, index) => <li key={index}>
              { (persons[person]?.anrede ? persons[person].anrede + " " : "") }
              {persons[person]?.name}
              {(persons[person]?.vorname) ? ", " + persons[person].vorname : ""}
              </li>)
          }
          </ul>
          <p>Bitte die E-Mail Adressen hinzufügen oder Zugang der Personen entfernen.</p>
        </Alert>
      }
      <Row>
        <Col align="end">
          <ButtonGroup>
            <Button variant="outline-primary" onClick={goBack}>Zurück</Button>
            <Button disabled={Object.keys(licenses).length === 0} onClick={submitContract}>Verbindlich einrichten</Button>
          </ButtonGroup>
        </Col>
      </Row>
      </>
      }
    </>
  )
}

export default Contract