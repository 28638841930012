import { Register, App, Auth, NotFound, FirstLogin, Clients, Areas, Team, Dashboard, User, Products} from "../../components";

export const publicRoutes = [
  {
    path: "/register",
    element: <Register />
  },
  {
    path: "/firstLogin/:emailAddr?",
    element: <FirstLogin />
  },
  {
    path: "*",
    index: true,
    element: <Auth />,
  },
];

export const privateRoutes = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "areas/:country?/:region?/:area?/:district?",
        element: <Areas />,
      },
      {
        path: "clients",
        element: <Clients />
      },
      {
        path: "team",
        element: <Team />,
      },
      {
        path: "personal",
        element: <User />,
      },
      {
        path: "products",
        element: <Products />,
      },
      {
        index: true,
        element: <Dashboard />,
      },
    ],
  },
  {
    path: "*",
    element: <App />,
    children: [
      {
        path: "*",
        index: true,
        element: <NotFound />,
      }
    ]
  }
];