import { useSelector, useDispatch } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { authListener } from '../../root/redux/actions/auth/listener';
import { privateRoutes, publicRoutes } from '../../root/config/routes';

const AutoLogin = () => {

  const user = useSelector(state => state.auth);
  const router = (user.isLoggedIn && user?.tokens?.sales && user.emailVerified) ? createBrowserRouter(privateRoutes) : createBrowserRouter(publicRoutes);
  const dispatch = useDispatch();
  if(!user.isLoaded) {
    dispatch(authListener());
  }
  
  return (
    <RouterProvider router={router} />
  )
}

export default AutoLogin