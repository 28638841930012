import { useState } from "react"
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from "react-bootstrap/Table";
import { defaultData, defaultFunctions, defaultIncludesList, defaultRights, defaultRightsList } from "./defaultData";
import { FaTimes, FaArrowUp, FaArrowDown } from "react-icons/fa";
import { getDatabase, push, ref } from "firebase/database";


const AddNew = ({products = null, obj = {}, objId = null, setClose }) => {

  const [newProduct, setNewProduct] = useState(( objId !== null ? {...defaultData, ...obj} : {...defaultData} ));
  const [newFunctions, setNewFunctions] = useState((objId === null ? [defaultFunctions] : obj?.functions));
  const [newIncludes, setNewIncludes] = useState((objId === null ? [defaultIncludesList] : obj?.includes));
  const [newRights, setNewRights] = useState((objId === null ? [defaultRights] : obj?.rights));
  const [newRequiers, setNewRequiers] = useState((objId === null ? [] : obj?.requiered));
  const [isDirty, setDirty] = useState(false);
  

  const handleChange = e => {
    setDirty(true);
    setNewProduct(prev => ({...prev, [e.target.name]: e.target.value}));
  }
  const handleChangeFunctions = (index) => (e) => {
    setDirty(true);
    const { name, value } = e.target;
    setNewFunctions((prevFunctions) =>
      prevFunctions.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      )
    );
  };
  const handleCheckboxChange = (e) => {
    const name = e.target.name;
    if (newRequiers.includes(name)) {
      setNewRequiers((prevRequiers) => prevRequiers.filter((item) => item !== name));
    } else {
      setNewRequiers((prevRequiers) => [...prevRequiers, name]);
    }
  };
  const handleChangeIncludes = (index) => (e) => {
    setDirty(true);
    const { name, value } = e.target;
    setNewIncludes((prevIncludes) =>
      prevIncludes.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      )
    );
  };
  const handleRightsCkeckboxChange = (index) => (e) => {
    setDirty(true);
    const { name } = e.target;
    setNewRights((prevRights) => {
      const updatedRights = prevRights.map((right, i) => {
        if (i === index) {
          const updatedRight = { ...right };
          if (updatedRight.right.includes(name)) {
            updatedRight.right = updatedRight.right.filter((item) => item !== name);
          } else {
            updatedRight.right = [...updatedRight.right, name];
          }
          return updatedRight;
        }
        return right;
      });
      return updatedRights;
    });
  };
  

  const addFunctions = () => {
    const arr = [...newFunctions];
    arr.push(defaultFunctions);
    setNewFunctions(arr);
  }
  const moveFunctionUp = (index) => {
    if (index > 0) {
      const arr = [...newFunctions];
      [arr[index], arr[index - 1]] = [arr[index - 1], arr[index]];
      setNewFunctions(arr);
    }
  };
  const moveFunctionDown = (index) => {
    if (index < newFunctions.length - 1) {
      const arr = [...newFunctions];
      [arr[index], arr[index + 1]] = [arr[index + 1], arr[index]];
      setNewFunctions(arr);
    }
  };
  const removeFunctions = (index) => {
    const arr = [...newFunctions];
    arr.splice(index, 1);
    setNewFunctions(arr);
  };
  const addRights = () => {
    const arr = [...newRights];
    arr.push(defaultRights);
    setNewRights(arr);
  }
  const moveRightUp = (index) => {
    if (index > 0) {
      const arr = [...newRights];
      [arr[index], arr[index - 1]] = [arr[index - 1], arr[index]];
      setNewRights(arr);
    }
  };
  const moveRightDown = (index) => {
    if (index < newRights.length - 1) {
      const arr = [...newRights];
      [arr[index], arr[index + 1]] = [arr[index + 1], arr[index]];
      setNewRights(arr);
    }
  };
  const removeRights = (index) => {
    const arr = [...newRights];
    arr.splice(index, 1);
    setNewRights(arr);
  };

  const handleChangeRights = (index) => (e) => {
    setDirty(true);
    const { name, value } = e.target;
    setNewRights((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      )
    );
  };

  const handleReset = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if(window.confirm("Alle Eingaben verwerfen?")) {
      setNewProduct(defaultData);
      setNewFunctions([defaultFunctions]);
      setNewIncludes(defaultIncludesList);
      setNewRights([defaultRights]);
      setNewRequiers([]);
      setDirty(false);
      setClose();
    }
  }
  const handleSave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const data = {
      ...newProduct,
      functions: newFunctions,
      requiered: newRequiers,
      includes: newIncludes,
      rights: newRights
    }
    push(ref(getDatabase(),"products"), data)
    .then(() => {
      setNewProduct(defaultData);
      setNewFunctions([defaultFunctions]);
      setNewIncludes(defaultIncludesList);
      setNewRights([defaultRights]);
      setNewRequiers([]);
      setDirty(false);
      setClose();
    })
  }
  


  return (
  <Accordion.Item eventKey="newProduct">
    <Form>
    <Accordion.Header>Neues Produkt anlegen</Accordion.Header>
    <Accordion.Body>
      <Row>
        <Col xs={12} md={4}>
          <Form.Group>
            <Form.Text>Produktname</Form.Text>
            <Form.Control name="name" value={newProduct.name} onChange={handleChange} required/>
          </Form.Group>
        </Col>
        <Col xs={6} md={2}>
          <Form.Group>
            <Form.Text>Kurzbezeichnung</Form.Text>
            <Form.Control name="short" value={newProduct.short} onChange={handleChange} required/>
          </Form.Group>
        </Col>
        <Col xs={6} md={3}>
          <Form.Group>
            <Form.Text>Verfügbar ab</Form.Text>
            <Form.Control type="date" name="disponibleFrom" value={newProduct.disponibleFrom} onChange={handleChange} required/>
          </Form.Group>
        </Col>
        <Col xs={6} md={3}>
          <Form.Group>
            <Form.Text>Preis pro Monat</Form.Text>
            <InputGroup>
              <Form.Control type="number" name="price" step="0.01" min="0" value={newProduct.price} onChange={handleChange} required/>
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
        <Form.Group className="mt-3">
          <Form.Text>Produktbeschreibung</Form.Text>
          <Form.Control as="textarea" rows="3" name="description" value={newProduct.description} onChange={handleChange} required/>
        </Form.Group>
        <h4 className="mt-4">Funktionen</h4>
        <Table bordered hover striped>
        <thead>
          <tr>
            <th>Titel</th>
            <th>Beschreibung</th>
            <th>Aktion</th>
          </tr>
        </thead>
        <tbody>
        {
          newFunctions.map((item, index) => 
              <tr key={index}>
                <td>
                  <Form.Control name="name" value={item.name} onChange={handleChangeFunctions(index)} placeholder="Überschrift" required />
                </td>
                <td width="50%">
                  <Form.Control name="description" onChange={handleChangeFunctions(index)} value={item.description} placeholder="Beschreibung" required />
                </td>
                <td align="end">
                  <ButtonGroup>
                    <Button variant="primary" disabled={index === 0} onClick={() => moveFunctionUp(index)}><FaArrowUp /></Button>
                    <Button variant="primary" disabled={index + 1 === newFunctions.length} onClick={() => moveFunctionDown(index)}><FaArrowDown /></Button>
                    <Button variant="danger" onClick={() => removeFunctions(index)} ><FaTimes /></Button>
                  </ButtonGroup>
                </td>
              </tr>)
        }
        </tbody>
        </Table>
        <Button size="sm" variant="outline-primary" onClick={addFunctions}>Weitere Funktion hinzufügen</Button>
        <h4 className="mt-4">Berechtigungen</h4>
        <Table bordered hover striped>
        <thead>
          <tr>
            <th>Aktion</th>
            <th>Kürzel</th>
            <th>Auswahlmöglichkeit</th>
            <th>Aktion</th>
          </tr>
        </thead>
        <tbody>
        {
          newRights.map((item, index) => 
              <tr key={index}>
                <td width="40%">
                <Form.Control name="description" onChange={handleChangeRights(index)} value={item.description} placeholder="Beschreibung" required />
                </td>
                <td width="10%">
                  <Form.Control name="code" onChange={handleChangeRights(index)} value={item.code} maxLength={4} required />
                </td>
                <td>
                  {
                    Object.entries(defaultRightsList).map(([key, label], idx) => <Form.Check
                    inline
                    key={index +"-" + idx}
                    type="checkbox"
                    name={key}
                    checked={newRights[index].right.includes(key)}
                    onChange={handleRightsCkeckboxChange(index)}
                    label={label} />
                    )
                  }
                </td>
                <td align="end">
                  <ButtonGroup>
                    <Button variant="primary" disabled={index === 0} onClick={() => moveRightUp(index)}><FaArrowUp /></Button>
                    <Button variant="primary" disabled={index + 1 === newRights.length} onClick={() => moveRightDown(index)}><FaArrowDown /></Button>
                    <Button variant="danger" onClick={() => removeRights(index)} ><FaTimes /></Button>
                  </ButtonGroup>
                </td>
              </tr>)
        }
        </tbody>
        </Table>
        <Button size="sm" variant="outline-primary" onClick={addRights}>Weitere Berechtigung hinzufügen</Button>
        <h4 className="mt-4">Inkludiertes Volumen</h4>
        <Table bordered hover striped>
        <thead>
          <tr>
            <th>Bezeichnung</th>
            <th>Menge</th>
            <th>Einheit</th>
          </tr>
        </thead>
        <tbody>
        {
          newIncludes.map((item, index) => 
              <tr key={index}>
                <td xs="auto" valign="middle">{item.name}</td>
                <td xs="auto"><Form.Control value={item.qty} type="number" step={item.step} min={0} name="qty" onChange={handleChangeIncludes(index)} /></td>
                <td valign="middle">{item.format}</td>
              </tr>)
        }
        </tbody>
        </Table>
        {
          (products !== null && Object.keys(products).length > 0 ) && <>
            <h4 className="mt-4">Vorraussetzungen</h4>
            {
              Object.entries(products).map(([key, product], index) => <Form.Check
              key={index}
              type="checkbox"
              name={key}
              label={product.name}
              checked={newRequiers.includes(key)}
              onChange={handleCheckboxChange} />)
            }
          </>
        }
        <Row>
          <Col align="end">
            <ButtonGroup>
              <Button variant="outline-primary" disabled={!isDirty} onClick={handleReset}>Verwerfen</Button>
              <Button disabled={!isDirty} onClick={handleSave}>Speichern</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Accordion.Body>
    </Form>
  </Accordion.Item>);
}

export default AddNew