export const defaultData = {
  status: "Kontakt",
  anrede: "",
  vorname: "",
  name: "",
  adresszusatz: "",
  anschrift: "",
  PLZ: "",
  ort: "",
  land: "DEUTSCHLAND",
  telefon: "",
  email: "",
  webseite: "",
  branche: "",
  mitarbeiter: "",
  erstkontakt: "",
};
export const defaultStates = ["Kontakt", "Aktiv", "Inaktiv","Gesperrt","Verdächtig"];