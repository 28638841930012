export const defaultData = {
  role: "hv",
  anrede: "",
  vorname: "",
  name: "",
  displayName: "",
  adresszusatz: "",
  area: "",
  anschrift: "",
  PLZ: "",
  ort: "",
  land: "DEUTSCHLAND",
  mobil: "",
  telefon: "",
  start: "",
  end: "",
  email: "",
};
export const defaultRoles = [
  ["ceo", "Geschäftsführer"],
  ["cso", "Verkaufsleiter"],
  ["cmo","Marketingleiter"],
  ["rvl", "Regionverkaufsleiter"],
  ["gvl", "Gebietverkaufsleiter"],
  ["hv", "Handelsvertreter"],
  ["adm", "Vertriebsmitarbeiter"]
];
export const filterRoles = [
  ...defaultRoles,
  ["blocked", "Gesperrt"],
  ["deleted", "Gelöscht"]
];