import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

const Loading = () => {
  return (
    <Modal show={true} centered={true}>
      <Modal.Body className="text-center">
        <Spinner animation="border" variant="primary" />
        <br/>Einen Moment, der Inhalt wird geladen.
      </Modal.Body>
    </Modal>
  )
}

export default Loading;