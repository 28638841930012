import types from '../../types';
import { auth } from '../../../config/firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { onAuthStateChanged, signOut } from 'firebase/auth';

const functions = getFunctions();

const updateUserCallable = httpsCallable(functions, 'Sales_FirstTime');

export const authListener = () => dispatch => {
  try {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult(true).then((data) => {
          if(!data?.claims?.sales) {
            if(user.email === 'rico@werkapp.de') { 
              updateUserCallable({ email: user.email })
              .then((result) => {
                const message = result.data.message;
                console.log('Cloud Function erfolgreich aufgerufen:', message);
              })
              .catch((error) => {
                // Fehler beim Aufrufen der Cloud Function
                console.error('Fehler beim Aufrufen der Cloud Function:', error);
              });
            }

            signOut(auth)
            .then(() => {
              dispatch({type: types.AUTH_SIGNOUT})})
            .catch((error) => {
              dispatch({type: types.FIREBASE_ERROR, payload: error});
            });
          }
          else dispatch({type: types.AUTH_SIGNIN, payload: { displayName: user.displayName, email: user.email, emailVerified: user.emailVerified, isAnonymous: user.isAnonymous, photoURL: user.photoURL, uid: user.uid, tokens: { ...data?.claims } }});
        })
      } else {
        dispatch({type: types.FIREBASE_CONNECTED});
      }
    });
  }
  catch (e) {
    console.error(e);
  }
  
}