import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './root/redux/store';
import reportWebVitals from './root/functions/reportWebVitals';
import AutoLogin from './components/auth/AutoLogin';

import './root/style/index';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  
    <Provider store={store}>
      { <AutoLogin /> }
    </Provider>
  
);
reportWebVitals();