import initialState from './initialState';
import types from '../../types';

export default function app(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: {...state.contacts, ...action.payload},
        contactsLoaded: true,
        contactsError: null,
      }
    case types.LOAD_CONTACTS_FAILURE:
      return {
        ...state,
        contactsLoaded: false,
        contactsError: action.error,
      }
    case types.LOAD_TEAM_SUCCESS:
      return {
        ...state,
        team: action.payload,
        teamLoaded: true,
        teamError: null,
      }
    case types.LOAD_TEAM_FAILURE:
      return {
        ...state,
        teamLoaded: false,
        teamError: action.error,
      }
    case types.LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        productsLoaded: true,
        productsError: null,
      }
    case types.LOAD_PRODUCTS_FAILURE:
      return {
        ...state,
        productsLoaded: false,
        productsError: action.error,
      }
    case types.LOAD_AREAS_SUCCESS:
      return {
        ...state,
        areas: action.payload,
        areasLoaded: true,
        areasError: null,
      }
    case types.LOAD_AREAS_FAILURE:
      return {
        ...state,
        areasLoaded: false,
        areasError: action.error,
      }
    case types.LOAD_ZIP_SUCCESS:
      return {
        ...state,
        zip: action.payload,
        zipLoaded: true,
        zipError: null,
      }
    case types.LOAD_ZIP_FAILURE:
      return {
        ...state,
        zipLoaded: false,
        zipError: action.error,
      }
    case types.AUTH_SIGNOUT:
      return {
        ...initialState
      }
    /*
    case types.USERDATA_UPDATED:
      return { ...state,
        ...action.payload
      }*/
    default: return state;
  }
}