import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import { loader } from "../../../root/redux/actions/database/loader";
import Loading from "../../modals/Loading";
import Error from "../../modals/Error";
import { Link } from "react-router-dom";

const SingleDistrict = (props) => {

  const { id = null } = props;
  const {areas, zip, team, teamLoaded, teamError} = useSelector(state => state.app);
  
  const dispatch = useDispatch();

  useEffect(() => {
    if(!teamLoaded) {
      dispatch(loader("team"));
    }
  }, [dispatch, teamLoaded]);

  const obj = areas[id];

  if(teamError !== null) return <Error />;
  
  if(!teamLoaded) return <Loading />;

  const getZip = () => {
    let list = [];
    for ( const item in zip ) {
      if(zip[item]?.district === id) {
        list.push({...zip[item], id: item});
      }
    }
    return list;
  }
  const zipCodes = getZip();

  const getDetail = () => {
    let km = 0;
    let ppl = 0;
    for (const item in zipCodes) {
      km = km + zipCodes[item]?.km * 1;
      ppl = ppl + zipCodes[item]?.people * 1;
    }
    return {km: km, ppl: ppl};
  }
  const details = getDetail();

  const teamName = id => {
    if (team === null || team === undefined || Object.keys(team).length === 0) return <span style={{opacity:.3}}>leer</span>;
    for (const mate in team) {
      if(team[mate].hasOwnProperty("region") && team[mate].region === id) {
        return team[mate].displayName;
      }
    }
    return <span style={{opacity:.3}}>unbesetzt</span>;
  }

  return (
    <Card>
      <Card.Header>
        <h3 className="mt-3">Mein Verkaufsgebiet</h3>
        <p>
          Verkaufsgebiet: {obj.name}<br/>
          { (areas[obj.area].user !== "") && <>Gebiet: {areas[obj.area].name} ({ teamName(areas[obj.area].user)})<br/></>}
          { (areas[obj.region].user !== "") && <>Region: {areas[obj.region].name} ({ teamName(areas[obj.region].user)})<br/></>}
          { (areas[obj.country].user !== "") && <>Land: {areas[obj.country].name} ({ teamName(areas[obj.country].user)})</>}
        </p>
      </Card.Header>
      <Card.Body style={{fontWeight: 500}}>
      <p className="my-1">Fläche: { details.km.toFixed(2).replace(".",",").replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") } km{String.fromCharCode(0x00B2)}</p>
      <p className="my-1">Einwohner: { details.ppl.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }</p>
      <p className="my-1">Kundenpotential: ca. {((details.km * (354918 / 357280) + details.ppl * (354918 / 80322190))/2).toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")} Betriebe</p>
      <p className="my-1">Umsatzpotential: {(((details.km * (354918 / 357280) + details.ppl * (354918 / 80322190))/2)*210/1000).toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")} TEUR/mtl.</p>
      <p style={{fontSize:"12px", margin:"1rem 0 1rem"}}>ID: {id}</p>
      <h4 className="mt-2">Meine Postleitzahlen</h4>
        <div className="d-flex flex-wrap mb-4">
          {
            zipCodes.map((z, index) => <Badge as={Link} to={"https://www.plz-suche.org/de/plz-karte/postleitzahlengebiet-"+z.zip} rel="noopener nofollow" target="_blank" key={index} bg="primary" style={{fontSize: "14px",}} className="m-1" >{z.zip}xx</Badge>)
          }
        </div>
    </Card.Body>
    </Card>
  )
}

export default SingleDistrict