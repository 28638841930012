export const defaultRightsList = {
  0: "Kein Zugriff",
  1: "Nur Ansehen",
  2: "Nur Ausführen",
  3: "Bearbeiten",
  5: "Anlegen und Bearbeiten", 
  7:"Vollzugriff"
};
export const defaultIncludesList = [
  {key: "cloudStorage", name: "Cloud-Dokumentenspeicher", format: "GB", price: 0.5, priceUnit: 1, qty: 0, step: 0.1},
  {key: "projectStorage", name: "Projektspeicher", format: "Einträge", price: 0.25, priceUnit: 100, qty: 0, step: 10},
  {key: "contactStorage", name: "Kontaktspeicher", format: "Einträge", price: 0.25, priceUnit: 100, qty: 0, step: 10},
  {key: "cloudMail", name: "Cloud-Mail Versand", format: "Stück", price: 1.25, priceUnit: 100, qty: 0, step: 10},
];
export const defaultRights = {
  description: "",
  code: "",
  right: Object.keys(defaultRightsList),
};
export const defaultFunctions = {
  name: "", description: ""
}
const now = new Date();
export const defaultData = {
  name: "",
  short: "",
  description: "",
  image: "",
  functions: [defaultFunctions],
  requiered: [],
  included: [],
  rights: [defaultRights],
  disponibleFrom: now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2,0) + "-" + now.getDate().toString().padStart(2,0),
  price: 0,
}