import React from 'react'
import Container from 'react-bootstrap/Container'

const Register = () => {
  return (
    <Container>
        <div>Register</div>
    </Container>
  )
}

export default Register