import { useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import FilterAndSort from "./FilterAndSort";
import { FormSelect, InputGroup } from "react-bootstrap";
import { defaultRoles, filterRoles } from "./defaultData";
import MultiTool from "./MultiTool";
import { useSelector } from "react-redux";

const Team = () => {
  
  const [mode, setMode] = useState(null);
  const [obj, setObj] = useState(null);
  const [objId, setObjId] = useState(null);
  const { role } = useSelector(state => state.auth.tokens);
  const [q, setQ] = useState("");
  const [status, setStatus] = useState("Alle");

  const closeModal = () => { setMode(null); setObj(null); setObjId(null); }
  const modalAdd = () => { setObj(null); setObjId(null); setMode("add");}
  const modalDetail = ({id, el}) => { setObj(el); setObjId(id); setMode("detail");}
  

  return (<>
    <Card>
      <Card.Header>
        <Row>
          <Col xs={8} md={5}>
            <InputGroup>
            <FormControl value={q} onChange={e => {setQ(e.target.value)}} placeholder="Suche"/>
            <FormSelect style={{maxWidth:"200px"}} value={status} onChange={e => {setStatus(e.target.value)}}>
              {
                (role === "ceo" || role === "cmo" || role === "cso") ?
                filterRoles.map(([key, value],id) => <option key={id} value={key}>{value}</option>) :
                defaultRoles.map(([key, value],id) => <option key={id} value={key}>{value}</option>)
              }
              <option value="Alle">Alle</option>
            </FormSelect>
            </InputGroup>
          </Col>
          <Col align="end">
            {
              (role === "ceo" || role === "cmo" || role === "cso") && <Button variant="outline-primary" onClick={modalAdd}>Neues Team Mitglied</Button>
            }
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <FilterAndSort q={q} status={status} modalAdd={modalAdd} modalDetail={modalDetail} />
      </Card.Body>
        {
          (role === "ceo" || role === "cmo" || role === "cso") && 
          <Card.Footer align="end">
            <Button variant="outline-primary" onClick={modalAdd}>Neues Team Mitglied</Button>
          </Card.Footer>
        }
    </Card>
    <Modal show={(mode!==null)} onHide={closeModal} size="xl" backdrop="static">
      <Modal.Header closeButton closeVariant="white" style={{fontWeight:600, backgroundColor:"var(--primary)",color:"var(--white)"}}>
        { (mode === "add" && "Neues Team Mitglied anlegen") }
        { (mode !== "add" && "Team Mitglied: " + obj?.name + (obj?.vorname ? ", " + obj.vorname : "")) }
      </Modal.Header>
      <Modal.Body>
        <MultiTool obj={obj} objId={objId} mode={mode} closeModal={closeModal} />
      </Modal.Body>
    </Modal>
  </>);
}

export default Team