import Card from "react-bootstrap/Card"
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Security from "./Security";
import Commission from "./Commission";
import Payment from "./Payment";

const User = () => {
  return (
        <Tabs defaultActiveKey="security">
          <Tab eventKey="security" title="Sicherheit">
            <Card style={{borderTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop:0}}>
              <Security />
            </Card>
          </Tab>
          <Tab eventKey="commission" title="Provisionen">
            <Card style={{borderTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop:0}}>
              <Commission />
            </Card>
          </Tab>
          <Tab eventKey="payment" title="Zahlungsdaten">
            <Card style={{borderTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop:0}}>
              <Payment />
            </Card>
          </Tab>
        </Tabs>
  )
}

export default User