import Container from "react-bootstrap/Container";
import Menu from "./menu/Menu";
import { Outlet } from "react-router-dom";

const App = () => {
  return (
    <Container fluid>
    <Menu />
    <Outlet />
    </Container>
  )
}

export default App