import types from "../../types";
import { getAuth, signOut } from "@firebase/auth";

export const logout = () => async dispatch => {
  try {
  signOut(getAuth())
  .then(() => {
    dispatch({type: types.AUTH_SIGNOUT})})
  .catch((error) => {
    dispatch({type: types.FIREBASE_ERROR, payload: error});
  });
  }
  catch (error) {
    dispatch({type: types.FIREBASE_ERROR, payload: error});
  }
}