import React, { useState } from "react";
import { getAuth, updatePassword } from "firebase/auth";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { ImEye, ImEyeBlocked } from "react-icons/im";

const Security = () => {

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pwToggle, setPwToggle] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleReset = () => {
    setPassword("");
    setConfirmPassword("");
    setPwToggle(false);
    }

  const handleSave = (e) => {
    
    e.preventDefault();
    e.stopPropagation();
    setMessage("");

    if ( password.length < 10 ||
      !/.*[a-z].*/.test(password) ||
      !/.*[A-Z].*/.test(password) ||
      !/.*[0-9].*/.test(password) ||
      !/.*[^a-zA-Z0-9].*/.test(password) ) {
        setMessageType("danger");
        setMessage("Dein Passwort ist nicht sicher");
        return;
    }
    else if ( password !== confirmPassword ) {
      setMessageType("danger");
      setMessage("Deine Passwörter stimmen nicht überein");
      return;
    }
    
    const user = getAuth().currentUser;
    
    updatePassword(user, password)
    .then(() => {
      setMessageType("primary");
      setMessage("Dein Passwort wurde aktualisiert");
      setPassword("");
      setConfirmPassword("");
      setPwToggle(false);
    })
    .catch((error) => {
      setMessageType("danger");
      setMessage("Es ist ein Fehler aufgetreten");
    });
  };


  return (
    <Card.Body>
      <h4>Dein Passwort ändern</h4>
      {message && <Alert variante={messageType}>{message}</Alert>}
      <Form onSubmit={handleSave}>
        <Row>
          <Col xs={8} md={3}>
            <Form.Group className="mb-3">
              <Form.Text>Passwort</Form.Text>
              <InputGroup>
                <Form.Control value={password} type={pwToggle?"text":"password"} name="password" id="password" onChange={handlePasswordChange} required/>
                <InputGroup.Text onClick={() => setPwToggle(!pwToggle)}>{pwToggle?<ImEyeBlocked />:<ImEye />}</InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Text>Wiederholung</Form.Text>
              <InputGroup>
                <Form.Control value={confirmPassword} type={pwToggle?"text":"password"} id="confirmPassword" name="confirmPassword" onChange={handleConfirmPasswordChange} required/>
                <InputGroup.Text onClick={() => setPwToggle(!pwToggle)}>{pwToggle?<ImEyeBlocked />:<ImEye />}</InputGroup.Text>
              </InputGroup>
            </Form.Group>
                {
                  (password.length <= confirmPassword.length && password.length >= 10) ? (password === confirmPassword ? <p className="text-success m-0 p-0" style={{fontSize:"14px", fontWeight:500}}>Passwörter stimmen überein</p> : <p className="text-danger m-0 p-0" style={{fontSize:"14px", fontWeight:500}}>Passwörter stimmen nicht überein</p>) : <p /> 
                }
          </Col>
          <Col className="pt-4">
            <p className={(password.length >= 10 ? "text-success" : "text-danger") + " m-0 p-0"} style={{fontSize:"14px", fontWeight:500}}>Mindestens 10 Zeichen lang</p>
            <p className={(/.*[a-z].*/.test(password) ? "text-success" : "text-danger") + " m-0 p-0"} style={{fontSize:"14px", fontWeight:500}}>Kleinbuchstaben</p>
            <p className={(/.*[A-Z].*/.test(password) ? "text-success" : "text-danger") + " m-0 p-0"} style={{fontSize:"14px", fontWeight:500}}>Großbuchstaben</p>
            <p className={(/.*[0-9].*/.test(password) ? "text-success" : "text-danger") + " m-0 p-0"} style={{fontSize:"14px", fontWeight:500}}>Zahlen</p>
            <p className={(/.*[^a-zA-Z0-9].*/.test(password) ? "text-success" : "text-danger") + " m-0 p-0"} style={{fontSize:"14px", fontWeight:500}}>Sonderzeichen</p>
          </Col>
        </Row>
        <Row>
          <Col>
          <ButtonGroup>
            <Button variant="outline-primary" onClick={handleReset}>Abbrechen</Button>
            <Button type="submit">Speichern</Button>
          </ButtonGroup>
            
          </Col>
        </Row>
      </Form>
    </Card.Body>
  );
}

export default Security