import { useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import FilterAndSort from "./FilterAndSort";
import { FormSelect, InputGroup } from "react-bootstrap";
import { defaultStates } from "./defaultData";
import MultiTool from "./MultiTool";

const Clients = () => {
  
  const [mode, setMode] = useState(null);
  const [obj, setObj] = useState(null);
  const [objId, setObjId] = useState(null);
  const [q, setQ] = useState("");
  const [status, setStatus] = useState("Alle");
  const [showKPI, setShowKPI] = useState(false);

  const closeModal = () => { setMode(null); setObj(null); setObjId(null); }
  const modalAdd = () => { setObj(null); setObjId(null); setMode("add");}
  const modalDetail = ({id, el}) => { setObj(el); setObjId(id); setMode("detail");}

  return (<>
    <Card className="mb-3">
      <Card.Header>
        <Row>
          <Col xs={8} md={5}>
            <InputGroup>
            <FormControl value={q} onChange={e => {setQ(e.target.value)}} placeholder="Suche"/>
            <FormSelect style={{maxWidth:"200px"}} value={status} onChange={e => {setStatus(e.target.value)}}>
              {
                defaultStates.map((x,id) => <option key={id} value={x}>{x}</option>)
              }
              <option value="Alle">Alle</option>
            </FormSelect>
            </InputGroup>
          </Col>
          <Col align="end">
            <Button variant="outline-primary" onClick={modalAdd}>Neuer Kontakt</Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <FilterAndSort q={q} status={status} modalAdd={modalAdd} modalDetail={modalDetail} />
      </Card.Body>
      <Card.Footer>
        <Row style={{margin:"0", paddingLeft:0, paddingRight:0}}>
          <Col>
            <Button variant="outline-primary" onClick={() => setShowKPI(!showKPI)}>KPI {(showKPI ? "ausblenden":"anzeigen")}</Button>
          </Col>
          <Col align="end">
            <Button variant="outline-primary" onClick={modalAdd}>Neuer Kontakt</Button>
          </Col>
        </Row>
        {
        showKPI &&
        <Row>
          <hr style={{marginLeft:"-5px", marginTop: "7px", width:"calc(100% + 10px)", maxWidth:"unset"}}/>
          <Col xs="auto">
            <h6>Betriebsgröße</h6>
            A = ≥ 50 Mitarbeiter<br/>
            B = 49 - 20 Mitarbeiter<br/>
            C = 19 - 10 Mitarbeiter<br/>
            D = 9 - 5 Mitarbeiter<br/>
            E = ≤ 4 Mitarbeiter<br/>
            U = Keine Informationen
          </Col>
          <Col xs="auto">
            <h6>Umsatzpotential</h6>
            1 = ≥ 150%<br/>
            2 = 149 - 120%<br/>
            3 = 119 - 110%<br/>
            4 = 109 - 90%<br/>
            5 = 89 - 80%<br/>
            6 = ≥ 50%<br/>
            7 = &lt; 50%<br/>
            0 = 0
          </Col>
          <Col xs="auto">
            <h6>Status</h6>
            1 = Aktiv<br/>
            3 = Kontakt<br/>
            5 = Inaktiv<br/>
            7 = Gesperrt<br/>
            9 = Verdächtig<br/>
          </Col>
        </Row>
        }
      </Card.Footer>
    </Card>
    <Modal show={(mode!==null)} onHide={closeModal} size="xl" backdrop="static">
      <Modal.Header closeButton closeVariant="white" style={{fontWeight:600, backgroundColor:"var(--primary)",color:"var(--white)"}}>
        { (mode === "add" && "Neuen Kontakt anlegen") }
        { (mode !== "add" && "Kontakt: " + obj?.name + (obj?.vorname ? ", " + obj.vorname : "")) }
      </Modal.Header>
      <Modal.Body>
        <MultiTool obj={obj} objId={objId} mode={mode} closeModal={closeModal} />
      </Modal.Body>
    </Modal>
  </>);
}

export default Clients