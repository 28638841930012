import Alert from "react-bootstrap/Alert";

const Error = () => {
  return (
    <Alert variant="danger">
      Etwas ist schiefgelaufen...
    </Alert>
  )
}

export default Error