import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { loader } from "../../../root/redux/actions/database/loader";
import Loading from "../../modals/Loading";
import Error from "../../modals/Error";
import { getDatabase, push, ref, update } from "firebase/database";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import District from "./District";


const Area = (props) => {

  const { id = null, parentId = null} = props;
  const {areas, zip, team, teamLoaded, teamError} = useSelector(state => state.app);
  
  const initialData = {name: "", type: "area", region: parentId, country: areas[parentId]?.country, user: ""};

  const [list, setList] = useState([]);
  const [item, setItem] = useState(initialData);
  const [isDirty, setDirty] = useState(false);
  const startRef = useRef(null);
  const endRef = useRef(null);
  const areaRef = useRef(null);

  const handleDrop = (result) => {
    const { draggableId, source, destination } = result;
    if(destination === null || draggableId === null) return false;
    if(destination.droppableId === source.droppableId) return false;
    if(destination.droppableId === "zipcodes") {
      update(ref(getDatabase(),"zip/" + draggableId), {area: ""})
      .then(() => { return true; })
      .catch((err) => {console.log(err); return false;});
    } else {
      update(ref(getDatabase(),"zip/" + draggableId), {area: destination.droppableId})
      .then(() => { return true; })
      .catch((err) => {console.log(err); return false;});
    }
  };

  const dispatch = useDispatch();

  const handleChange = e => {
    setDirty(true);
    setItem(prev => ({...prev, [e.target.name]: e.target.value}));
  }


  const handleSave = () => {
    const db = getDatabase();
    push(ref(db, "areas/"), item)
    .then(() => {
      setDirty(false);
      setItem(initialData);
    })
    .catch(err => {
      console.error(err);
    });
  }
  const handleReset = () => {
    setDirty(false);
    setItem(initialData);
  }

  useEffect(() => {
    if(!teamLoaded) {
      dispatch(loader("team"));
    }
  }, [dispatch, teamLoaded]);

  useEffect(() => {  
    let temp = [];
    for (const item in areas) {
      if(areas[item].type === "area") {
        if(parentId !== null) {
          if(areas[item].region === parentId) {
            temp.push({...areas[item], id: item});
          } else continue;
        } else temp.push({...areas[item], id: item});
      }
    }
    temp.sort((a, b) => a.name.localeCompare(b.name));
    setList(temp);
    // eslint-disable-next-line
  }, [areas])

  if(teamError !== null) return <Error />;
  
  if(!teamLoaded) return <Loading />;

  if(id !== null && areas.hasOwnProperty(id)) {
    return <District parentId={id} />
  }  
  const getZip = () => {
    let list = [];
    for ( const item in zip ) {
      if((!zip[item]?.area || zip[item].area === "") && zip[item].region === parentId) {
        list.push({...zip[item], id: item});
      }
    }
    list.sort((a, b) => a.zip.localeCompare(b.zip));
    return list;
  }
  const zipList = getZip();


  const zipBatch = () => {
    const start = parseInt(startRef.current.value);
    const end = parseInt(endRef.current.value);
    const target = areaRef.current.value

    if(start === 0 || start === null || end === 0 || end === null) return;

    for (const item of zipList) {
      const zipCode = parseInt(item.zip);
      if(zipCode >= start && zipCode <= end && target !== item.area) {
        update(ref(getDatabase(),"zip/" + item.id), {area: target, district: ""});
      }
    }
  }

  return (
    <Card>
      <Card.Header>Gebiete{ (parentId === null) ? "" : " in " + areas[parentId].name + " (" + areas[areas[parentId]?.country]?.name + ")"}</Card.Header>
      <DragDropContext onDragEnd={handleDrop}>
      <Card.Body>
        <Row>
          
          {
            list.map((obj, index) => <Col key={index} xs={12} md={4} lg={3} ><Item obj={obj} handleDrop={handleDrop} /></Col>)
          }
          <Col xs={12} md={4} lg={3}>
            <Card>
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Text>Bezeichnung</Form.Text>
                  <Form.Control name="name" value={item.name} onChange={handleChange} required />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Text>Zuweisen:</Form.Text>
                  <Form.Select value={item.user} name="user" onChange={handleChange}>
                    <option value="">Nicht zugewiesen</option>
                    {
                      Object.entries(team).map(([key, value], index) => <option value={key} key={index}>{value.displayName}</option>)
                    }
                  </Form.Select>
                </Form.Group>
              </Card.Body>
              <Card.Footer align="end">
                <ButtonGroup>
                  <Button variant="outline-primary" disabled={!isDirty} onClick={handleReset}>Verwerfen</Button>  
                  <Button variant="primary" disabled={!isDirty} onClick={handleSave}>Speichern</Button>  
                </ButtonGroup>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <h5 className="mt-4">Postleitzahlen</h5>
        <Row>
          <Col>
            <h6>Schnellzuweisung</h6>
            <Row>
              <Col xs={6} md={2}>
                <Form.Group>
                  <Form.Text>Von</Form.Text>
                  <Form.Control ref={startRef} type="number" min="10" max="999" step="1" maxLength={3} minLength={3} />
                </Form.Group>
              </Col>
              <Col xs={6} md={2}>
                <Form.Group>
                  <Form.Text>Bis</Form.Text>
                  <Form.Control ref={endRef} type="number" min="10" max="999" step="1" maxLength={3} minLength={3} />
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group>
                  <Form.Text>Zuweisen an:</Form.Text>
                  <Form.Select ref={areaRef}>
                    <option value="">Keine Zuweisung</option>
                    {
                      list.map((obj, index) => <option key={index} value={obj.id}>{ obj.name }{ obj?.user !== "" ? " (" + team[obj.user].displayName + ")" : ""}</option>)
                    }
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Button className="mt-4" onClick={zipBatch}>Zuweisen</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <h6>Liste</h6>
          <Col xs={12}>
          <Droppable droppableId="zipcodes">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex flex-wrap gap-1 justify-content-between">
                {zipList.map((z, index) => (
                    <Draggable
                      key={z.id}
                      draggableId={z.id}
                      index={index}
                    >
                      {(provided) => (
                        <div 
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className="d-inline">
                        <Badge
                          bg="primary"
                          style={{
                            fontSize: "14px",
                          }}
                          >
                            {z.zip}xx
                          </Badge>
                          </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          </Col>
        </Row>
      </Card.Body>
      </DragDropContext>
    </Card>
  )
}

const Item = (props) => {
  const { team, zip } = useSelector(state => state.app);
  const {obj} = props;
  const [item, setItem] = useState({...obj, id: null});
  const [edit, setEdit] = useState(false);

  const handleChange = e => {
    setItem(prev => ({...prev, [e.target.name]: e.target.value}));
  }

  const handleSave = () => {
    const db = getDatabase();
    update(ref(db, "areas/"+obj.id), {...item, id: null})
    .then(() => {
      setEdit(false);
    })
    .catch(err => {
      console.error(err);
    });
  }
  const handleReset = () => {
    setEdit(false);
    setItem({...obj, id: null});
  }

  const getZip = () => {
    let list = [];
    for ( const item in zip ) {
      if(zip[item]?.area === obj.id) {
        list.push({...zip[item], id: item});
      }
    }
    return list;
  }

  const teamFiltered = () => {
    let list = [];
    for (const item in team) {
      if((team[item].role === "gvl") && (team[item].area === "" || team[item].area === null)) {
        list.push({...team[item], id: item});
      }
    }
    return list;
  }
  const teamList = teamFiltered();


  if(edit) {
  return(<Card className="mb-2">
    <Card.Body>
      <Form.Group className="mb-3">
        <Form.Text>Bezeichnung</Form.Text>
        <Form.Control name="name" value={item.name} onChange={handleChange} required />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Text>Zuweisen:</Form.Text>
        <Form.Select value={item.user} name="user" onChange={handleChange}>
          <option value="">Nicht zugewiesen</option>
          {
            teamList.map((person, index) => <option value={person.id} key={index}>{person.displayName}</option>)
          }
        </Form.Select>
      </Form.Group>
    </Card.Body>
    <Card.Footer align="end">
      <ButtonGroup>
        <Button variant="outline-primary" onClick={handleReset}>Verwerfen</Button>  
        <Button variant="primary" onClick={handleSave}>Speichern</Button>  
      </ButtonGroup>
    </Card.Footer>
  </Card>);
  }

  const zipCodes = getZip();

  const getDetail = () => {
    let km = 0;
    let ppl = 0;
    for (const item in zipCodes) {
      km = km + zipCodes[item]?.km * 1;
      ppl = ppl + zipCodes[item]?.people * 1;
    }
    return {km: km, ppl: ppl};
  }
  const details = getDetail();

  return (<Card className="mb-2">
    <Card.Body>
      <Card.Title className="py-0">{ obj.name }</Card.Title>
      <p style={{fontWeight:500, fontSize:"12px"}} className="my-1">Fläche: { details.km.toFixed(2).replace(".",",").replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") } km{String.fromCharCode(0x00B2)}</p>
      <p style={{fontWeight:500, fontSize:"12px"}} className="my-1">Einwohner: { details.ppl.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }</p>
      <p style={{fontWeight:500, fontSize:"12px"}} className="my-1">Kundenpotential: ca. {((details.km * (354918 / 357280) + details.ppl * (354918 / 80322190))/2).toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")} Betriebe</p>
      <p style={{fontWeight:500, fontSize:"12px"}} className="my-1">Umsatzpotential: {(((details.km * (354918 / 357280) + details.ppl * (354918 / 80322190))/2)*210/1000).toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")} TEUR/mtl.</p>
      <Form.Group className="mt-3">
        <Form.Text>Zugewiesen</Form.Text>
        <Form.Control plaintext readOnly value={(!obj?.user || obj.user === "") ? "Nicht zugeteilt" : team[obj.user].displayName} />
      </Form.Group>
      <p style={{fontSize:"12px", margin:".5rem 0 0"}}>ID: {obj.id}</p>
      <h6 className="mt-2">Postleitzahlen</h6>
      <Droppable droppableId={ obj.id }>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex flex-wrap gap-1 justify-content-between">
            {zipCodes.map((z, index) => (
                <Draggable
                  key={z.id}
                  draggableId={z.id}
                  index={index}
                >
                  {(provided) => (
                    <div 
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef} >
                      <Badge
                        bg="primary"
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        {z.zip}xx
                      </Badge>
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Card.Body>
    <Card.Footer align="end">
      <ButtonGroup>
        <Button variant="outline-primary" onClick={() => setEdit(true)}>Bearbeiten</Button>
        <Button as={Link} to={"/areas/" + obj.country + "/" + obj.region + "/" + obj.id}>Detail</Button>
      </ButtonGroup>
      
    </Card.Footer>
  </Card>)


}

export default Area