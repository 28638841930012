const branchen = ["Maurer und Betonbauer", "Garten- und Landschaftsbau", "Elektriker", "Spezialgerüstbau", "Industriegerüstbau", "Hochbau", "Tiefbau", "Hausmeisterservice", "Zimmerei", "Dachdecker", "Straßenbau", "Wärme-, Kälte- und Schallschutz", "Brunnenbau", "Gerüstbau", "Betonsteinhersteller", "Terrazzoherstellung", "Eisenflechter", "Stahlbetonbauer", "Ofen- und Luftheizungsbau", "Stuckateure", "Maler und Lackierer", "Metallbau", "Kälteanlagenbau", "Klempner", "Spengler", "Installateur und Heizungsbau", "Elektrotechnik", "Tischlerei", "Glaserei", "Fliesen-, Platten- und Mosaikleger", "Estrichleger", "Parkettleger", "Rolladen- und Sonnenschutz", "Schilder- und Leuchtreklamen", "Raumausstatter", "Gebäudereiniger", "Holz- und Bautenschützer", "Eisenflechter", "Bautentrocknung", "Bodenleger", "Asphaltierer", "Fuger im Hochbau", "Rammgewerbe", "Betonbohrer und -schneider", "Rohr- und Kanalreinigung", "Kabelverlegung im Hochbau"];
branchen.sort((a, b) => a < b ? -1 : a > b ? 1 : 0);

const numberFormatOptions = {
  style: 'decimal',
  useGrouping: true,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  minimumIntegerDigits: 1,
  currency: undefined, // Währungssymbol nicht anzeigen
  currencyDisplay: 'symbol', // Währungssymbol nicht anzeigen
  notation: 'standard',
  compactDisplay: 'short',
  localeMatcher: 'best fit',
  numberingSystem: 'latn',
  signDisplay: 'auto',
  signStyle: 'standard',
};

const initialState = {
  contacts: {},
  contactsLoaded: false,
  contactsError: null,
  settings: {
    rechtsformen: [['Einzelunternehmen','Einzelunternehmen'],['e.K.','eingetragener Kaufmann (e.K.)'],['GbR','Gesellschaft bürgerlichen Rechts (GbR)'],['AG','Aktiengesellschaft (AG)'],['GmbH','Gesellschaft mit beschränkter Haftung (GmbH)'],['GmbH & Co. KG','Kommanditgesellschaft mit beschränkter Haftung (GmbH & Co. KG)'],['KG','Kommanditgesellschaft (KG)'],['KGaA','Kommanditgesellschaft auf Aktien (KGaA)'],['OHG','Offene Handelsgesellschaft (OHG)'],['UG','Unternehmergesellschaft (UG)'],['SE','Europäische Gesellschaft (SE)'],['eG','Genossenschaft (eG)'],['KdöR','Körperschaft des öffentlichen Rechts']],
    branchen,
    quellen: ["Außendienst","Empfehlung","Messe/Veranstaltung","Andere"],
    nof: numberFormatOptions,
  },
  team: {},
  teamLoaded: false,
  teamError: null,
  areas: {},
  areasLoaded: false,
  areasError: null,
  zip: {},
  zipLoaded: false,
  zipError: null,
  products: {},
  productsLoaded: false,
  productsError: null,
  
};
export default initialState;