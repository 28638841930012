import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { loader } from "../../../root/redux/actions/database/loader";
import Loading from "../../modals/Loading";
import Error from "../../modals/Error";
import { getDatabase, push, ref, update } from "firebase/database";
import { Link } from "react-router-dom";
import Region from "./Region";


const Country = (props) => {

  const { id = null} = props;
  const {areas, team, teamLoaded, teamError} = useSelector(state => state.app);
  
  const initialData = {name: "", type: "country", user: ""};

  const [list, setList] = useState([]);
  const [item, setItem] = useState(initialData);
  const [isDirty, setDirty] = useState(false);

  const dispatch = useDispatch();

  const handleChange = e => {
    setDirty(true);
    setItem(prev => ({...prev, [e.target.name]: e.target.value}));
  }

  const handleSave = () => {
    const db = getDatabase();
    push(ref(db, "areas/"), item)
    .then(() => {
      setDirty(false);
      setItem(initialData);
    })
    .catch(err => {
      console.error(err);
    });
  }
  const handleReset = () => {
    setDirty(false);
    setItem(initialData);
  }

  useEffect(() => {
    if(!teamLoaded) {
      dispatch(loader("team"));
    }
  }, [dispatch, teamLoaded]);

  useEffect(() => {  
    let temp = [];
    for (const item in areas) {
      if(areas[item].type === "country") {
        temp.push({...areas[item], id: item});
      }
    }
    setList(temp);
  }, [areas])

  if(teamError !== null) return <Error />;
  
  if(!teamLoaded) return <Loading />;

  if(id !== null && areas.hasOwnProperty(id)) {
    return <Region parentId={id} />;
  }  
  
  return (
    <Card>
      <Card.Header>Länder</Card.Header>
      <Card.Body>
        <Row>
          {
            list.map((obj, index) => <Col key={index} xs={12} md={4} lg={3} ><Item obj={obj} /></Col>)
          }
          <Col xs={12} md={4} lg={3}>
            <Card>
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Text>Bezeichnung</Form.Text>
                  <Form.Control name="name" value={item.name} onChange={handleChange} required />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Text>Zuweisen:</Form.Text>
                  <Form.Select value={item.user} name="user" onChange={handleChange}>
                    <option value="">Nicht zugewiesen</option>
                    {
                      Object.entries(team).map(([key, value], index) => <option value={key} key={index}>{value.displayName}</option>)
                    }
                  </Form.Select>
                </Form.Group>
              </Card.Body>
              <Card.Footer align="end">
                <ButtonGroup>
                  <Button variant="outline-primary" disabled={!isDirty} onClick={handleReset}>Verwerfen</Button>  
                  <Button variant="primary" disabled={!isDirty} onClick={handleSave}>Speichern</Button>  
                </ButtonGroup>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

const Item = (props) => {
  const team = useSelector(state => state.app.team);
  const {obj} = props;
  const [item, setItem] = useState({...obj, id: null});
  const [edit, setEdit] = useState(false);

  const handleChange = e => {
    setItem(prev => ({...prev, [e.target.name]: e.target.value}));
  }

  const handleSave = () => {
    const db = getDatabase();
    update(ref(db, "areas/"+obj.id), {...item, id: null})
    .then(() => {
      setEdit(false);
    })
    .catch(err => {
      console.error(err);
    });
  }
  const handleReset = () => {
    setEdit(false);
    setItem({...obj, id: null});
  }

  if(edit) {
  return(<Card className="mb-2">
    <Card.Body>
      <Form.Group className="mb-3">
        <Form.Text>Bezeichnung</Form.Text>
        <Form.Control name="name" value={item.name} onChange={handleChange} required />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Text>Zuweisen:</Form.Text>
        <Form.Select value={item.user} name="user" onChange={handleChange}>
          <option value="">Nicht zugewiesen</option>
          {
            Object.entries(team).map(([key, value], index) => <option value={key} key={index}>{value.displayName}</option>)
          }
        </Form.Select>
      </Form.Group>
    </Card.Body>
    <Card.Footer align="end">
      <ButtonGroup>
        <Button variant="outline-primary" onClick={handleReset}>Verwerfen</Button>  
        <Button variant="primary" onClick={handleSave}>Speichern</Button>  
      </ButtonGroup>
    </Card.Footer>
  </Card>);
  }
  return (<Card className="mb-2">
    <Card.Body>
      <Card.Title className="py-0">{ obj.name }</Card.Title>
      <Form.Group className="mt-3">
        <Form.Text>Zugewiesen</Form.Text>
        <Form.Control plaintext readOnly value={(!obj?.user || obj.user === "") ? "Nicht zugeteilt" : team[obj.user].displayName} />
      </Form.Group>
      
      <p style={{fontSize:"12px", margin:".5rem 0 0"}}>ID: {obj.id}</p>
    </Card.Body>
    <Card.Footer align="end">
      <ButtonGroup>
        <Button variant="outline-primary" onClick={() => setEdit(true)}>Bearbeiten</Button>
        <Button as={Link} to={"/areas/" + obj.id}>Detail</Button>
      </ButtonGroup>
      
    </Card.Footer>
  </Card>)


}

export default Country