import { getAuth, getIdTokenResult } from "firebase/auth";
import { getDatabase, ref, onValue, orderByChild, query, equalTo } from 'firebase/database';

let listener = {};

const loadSuccess = (typ, data) => ({
  type: 'LOAD_' + typ.toUpperCase() + '_SUCCESS',
  payload: data,
});

const loadFailure = (typ, error) => ({
  type: 'LOAD_' + typ.toUpperCase() + '_FAILURE',
  error,
});

export const loader = (where, as = '') => async dispatch => {

  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user);
  const {role, sales, area} = claims.claims;
  if(sales === null || sales === undefined || role === undefined || role === null) return {error: true};

  if(listener.hasOwnProperty(where)) {
    const unlisten = listener[where];
    unlisten();
    delete(listener[where]);
  }

  const typ = (as==='' ? where : as);
  
  let sourceRef;
  let multiquery = [];

  if(where === "contacts") {
    if(role === "admin" || role === "ceo" || role === "cmo" || role === "cso") {
      sourceRef = query(ref(getDatabase(), where));
    } else if(role === "rvl" || role === "gvl") {
      sourceRef = query(ref(getDatabase(), where),orderByChild("area/"+area),equalTo(true));
    } else if(role === "ssm") {
      sourceRef = query(ref(getDatabase(), where),orderByChild("online"),equalTo("ssm"));
    } else if (role === "hv" || role === "adm") {
      multiquery.push(query(ref(getDatabase(), where), orderByChild("area/" + area), equalTo(true)));
      multiquery.push(query(ref(getDatabase(), where), orderByChild("assignedTo"), equalTo(user.uid)));
    }
  }
  else {
    sourceRef = query(ref(getDatabase(), where));
  }
  if(multiquery.length === 0) {
    listener[where] = onValue(sourceRef, (snapshot) => {
        const data = snapshot.val();
      dispatch(loadSuccess(typ, data));
    }, (error) => {
      dispatch(loadFailure(typ, error));
    });
  }
  else {
    listener[where] = [];
    multiquery.forEach(subQuery => {
      const unlisten = onValue(subQuery, (snapshot) => {
        const data = snapshot.val();
        dispatch(loadSuccess(typ, data));
      }, (error) => {
        dispatch(loadFailure(typ, error));
      });
      listener[where].push(unlisten);
    });
  }
};

export const disableListeners = (specific = null) => {
  if(specific !== null && listener.hasOwnProperty(specific)) {
    const unlisten = listener[specific];
    // eslint-disable-next-line
    if ( typeof unlisten === 'array' ) {
      for (const subUnlisten in unlisten) {
        subUnlisten();
      }
    } else unlisten();
  } else if (specific === null) {
    Object.values(listener).forEach(unlisten => {
      // eslint-disable-next-line
      if ( typeof unlisten === 'array' ) {
        for (const subUnlisten in unlisten) {
          subUnlisten();
        }
      } else unlisten();
    });
  }
};